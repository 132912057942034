import React from "react";
import {NavLink} from "react-router-dom";
import classes from './studentMenu.module.css'


const StudentMenu = () => {
    const exitBtn = () => {
        localStorage.clear();
    }
    return (
        <div className="container-fluid my-container-student">
            <div className={classes.menuWrap}>
                <NavLink to="/taskList" className={classes.link}>
                    Список заданий
                </NavLink>

                <NavLink to="/testStudentPage" className={classes.link}>
                    Тесты
                </NavLink>

                <NavLink to="/" onClick={exitBtn} className={classes.close}>
                    Выйти
                </NavLink>


            </div>

        </div>

    )
}

export default StudentMenu;