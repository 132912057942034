import React from "react";
import classes from './modalTags.module.css'



const ModalTags = ({showModal, tagsArr, apply,onChangeCheckbox,data}) => {
    return (

        <div className="main_modal">
            <div className="modal_my" onClick={showModal} >
            </div>
            <div className={classes.modal_wrapper}>
                <h3>Список тегов</h3>
                <div className={classes.checkboxes}>
                    {tagsArr.map((tag, key) => {
                        let checked = data.theme.indexOf(tag.id) < 0 ? false : true
                        return (
                            <div className={classes.checkbox} key={key}>
                                <label className="checkContainer">
                                    {tag.title}
                                    <input type="checkbox" value={tag.id} checked={checked} onChange={onChangeCheckbox} data-id={tag.id}  value={tag.id}/>
                                    <span className="checkmark" data-id={tag.id} />
                                </label>
                            </div>
                        )
                    })}
                </div>
                <button onClick={apply}  className={classes.applyBtn}>Применить</button>
            </div>
        </div>
    )
}

export default ModalTags;