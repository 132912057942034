import React from "react";
import classes from "./menuLog.module.css";


const MenuLog = ({stagesGroup, btnForStage, active}) => {
    return (
            <ul className="d-flex">
                {stagesGroup.map((item, key) =>

                {
                    return (
                        <li key={key}>
                            <button data-id={item.stage.id}
                                    className={active == item.stage.id ? classes.linkActive : classes.link}
                                    onClick={btnForStage}>
                                {item.stage.title}
                            </button>
                        </li>
                    )
                }




                )}
            </ul>

    )

}

export default MenuLog;