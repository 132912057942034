import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {API, BASE_URL, BASE_URL_SECOND} from "../api/api";
import {toast} from "react-toastify";
import {hidePreloader, showPreloader} from "./preloaderSlice";

export const getStudentTests = createAsyncThunk(
    'getStudentTests',
    async (token, {rejectWithValue, dispatch}) => {
        try {
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${token[0].auth_token}`
                }
            }
            const response = await fetch(`${BASE_URL}${API.student.getTests}`, options);
            dispatch(showPreloader())

            if (response.ok) {
                const data = await response.json();
                dispatch(getAllTestsReducer({data}))
                dispatch(hidePreloader())
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)


export const getOneResult = createAsyncThunk(
    'getOneResult',
    async (data, {rejectWithValue, dispatch}) => {
        try {
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                },

            }

            dispatch(showPreloader())

            const response = await fetch(`${BASE_URL}${API.student.getResultTest}${data.id}/`, options);
            if (response.ok) {
                const data = await response.json();
                dispatch(getOneResultTest({data}))

                dispatch(hidePreloader())

            } else {
                toast.error("Неизвестная ошибка")
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)


export const getOneTest = createAsyncThunk(
    'getOneTest',
    async (data, {rejectWithValue, dispatch}) => {
        try {
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                },

            }
            const response = await fetch(`${BASE_URL_SECOND}${API.student.oneTest}?id=${data.id}`, options);

            if (response.ok) {
                const data = await response.json();
                dispatch(getOneTestReducer({data}))
            } else {
                const error = await response.json();
                const data = {
                    id: null,
                    questions: [],
                    questionsCount: null,
                    title: ""
                }
                dispatch(getOneTestReducer({data}))
                dispatch(showModalReducer());
                toast.error(error.message)
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)


export const getResultTest = createAsyncThunk(
    'getResultTest',
    async (data, {rejectWithValue, dispatch}) => {

        console.log(data)

        const answerData = {
            grade: data.result.correctAnsweredQuestions,
            result: data.result.resultSubjects,
            test_name: data.result.testName,
            total: data.result.totalQuestions
        }
        try {
            let options = {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.data.tokenState[0].auth_token}`
                },
                body: JSON.stringify(answerData)
            }

            dispatch(showPreloader())

            const response = await fetch(`${BASE_URL}${API.student.getResultTest}${data.data.mainId}/`, options);
            if (response.ok) {
                const data = await response.json()
                dispatch(showModalReducer());
                dispatch(getOneResultTest({data}))
                dispatch(hidePreloader())
            } else {
                dispatch(hidePreloader())
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }


    }
)


export const sendAnswers = createAsyncThunk(
    'sendAnswers',
    async (data, {rejectWithValue, dispatch}) => {
        try {
            let options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                },
                body: JSON.stringify(data.data)
            }
            const response = await fetch(`${BASE_URL_SECOND}${API.student.checkTest}`, options);
            if (response.ok) {
                const result = await response.json()
                dispatch(getResultTest({data, result}))
            } else {
                const result = await response.json();
                dispatch(showModalReducer());
                toast.error(result.message)
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)


const studentTestsSlice = createSlice({
    name: 'studentTests',
    initialState: {
        studentTests: [],
        oneStudentTest: {
            id: null,
            questions: [],
            questionsCount: null,
            title: ""
        },
        oneResultTest: {
            grade: null,
            id: null,
            result: [],
            status: null,
            student: 7,
            test: null,
            test_name: null,
            total: null

        },
        modal: false
    },
    reducers: {
        getAllTestsReducer(state, action) {
            state.studentTests = action.payload.data
        }
        ,
        getOneTestReducer(state, action) {
            state.oneStudentTest = action.payload.data
        }
        ,
        getOneResultTest(state, action) {
            state.oneResultTest = action.payload.data
        },
        showModalReducer(state) {
            state.modal = true
        },
        hideModalReducer(state) {
            state.modal = false
        }
    }
})

export const {getAllTestsReducer, getOneTestReducer, getOneResultTest, showModalReducer, hideModalReducer} = studentTestsSlice.actions;

export default studentTestsSlice.reducer