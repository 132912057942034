import React from "react";
import {NavLink, useNavigate} from "react-router-dom";
import classes from "./menu.module.css";
import exitSvg from '../../images/exit.svg';
import tests from '../../images/tests.svg'
import settings from "../../images/settings.svg"


const Menu = () => {
    const navigation = useNavigate();

    const exit = () => {
        localStorage.clear()
        navigation('/')
    }

    return (
        <div className={`${classes.menu__wrap}`}>
            <NavLink to="/mentorPage" className={classes.link}>
                <div className={classes.icon_wrap}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                         className="bi bi-house" viewBox="0 0 16 16">
                        <path fillRule="evenodd"
                              d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/>
                        <path fillRule="evenodd"
                              d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/>
                    </svg>
                </div>
                <div className={classes.menuName}> Главная</div>
            </NavLink>

            <NavLink to="/downloadPage" className={classes.link}>
                <div className={classes.icon_wrap}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                         className="bi bi-download" viewBox="0 0 16 16">
                        <path
                            d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                        <path
                            d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                    </svg>
                </div>

                <div className={classes.menuName}> Загрузки</div>
            </NavLink>
            <NavLink to="/testsPage" className={classes.link}>
                <div className={classes.icon_wrap}>
                    <img src={tests} alt={tests}/>
                </div>
                <div className={classes.menuName}> Тесты</div>
            </NavLink>

            <NavLink to="/settingsPage" className={classes.link}>
                <div className={classes.icon_wrap}>
                    <img src={settings} alt={settings}/>
                </div>
                <div className={classes.menuName}> Настройки</div>
            </NavLink>


            <button className={classes.exitBtn} onClick={exit}>
                <div className={classes.icon_wrap}>
                    <img src={exitSvg} alt={exitSvg}/>
                </div>

                <div className={classes.menuName}> Выйти</div>
            </button>

        </div>

    )
}


export default Menu;