import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {BASE_URL} from "../api/api";
import {showPreloader, hidePreloader} from "./preloaderSlice";
import {toast} from "react-toastify";


export const getOneStudentTask = createAsyncThunk(
    "studentsTask/getOneStudentTask",
    async function (data, {rejectWithValue, dispatch}) {

        try {
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                }
            }

            dispatch(showPreloader());
            const result = {
                info: {
                    data: []
                }
            }
            dispatch(tableReducer({result}))
            dispatch(clearTaskInfoStudentResult())
            const response = await fetch(`${BASE_URL}/api/v1/tasks/student-task/${data.id}/`, options);
            if (response.ok) {
                const data = await response.json();
                dispatch(oneTaskInfoStudentReducer({data}))
                dispatch(hidePreloader())

            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const getSQLRightResult = createAsyncThunk(
    "studentsTask/getSQLRightResult",
    async function (data, {rejectWithValue, dispatch}) {

        try {
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                }
            }
            const response = await fetch(`${BASE_URL}/api/v1/sql/get-answer/${data.id}/`, options);
            if (response.ok) {
                const result = await response.json();
                dispatch(tableReducer({result}))
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchTaskPut = createAsyncThunk(
    "studentsTask/fetchTaskPut",
    async function (data, {rejectWithValue, dispatch}) {
        try {
            let options = {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                },
                body: JSON.stringify({solution: data.solution})
            }


            dispatch(onPreloader())
            const response = await fetch(`${BASE_URL}/api/v1/tasks/submit-task/${data.id}/`, options);

            if (response.ok) {
                const result = await response.json();
                const {tokenState, id} = data;
                dispatch(getOneStudentTask({tokenState, id}))
                dispatch(oneTaskInfoStudentResult({result}))
                if (result.status === 1) {
                    toast.success("Задание выполнено правильно");
                    dispatch(offPreloader())

                } else if (result.status === 2) {
                    toast.error("Задание выполнено не правильно")
                    dispatch(offPreloader())
                }
            }

        } catch (error) {

            return rejectWithValue(error.message)
        }
    }
)


const studentsTaskSlice = createSlice({
    name: "studentsTask",
    initialState: {
        oneTaskInfo: {
            solution: '',
            task: {
                description: '',
                sample_text: '',
                theme: [],
                language: {
                    id: 0,
                    title: "python"
                }
            },
        },
        results: {
            info: {
                data: [],
                message: ""
            }

        },
        table: {
            result: [],
        },
        preloader: false
    },
    reducers: {
        oneTaskInfoStudentReducer(state, action) {
            state.oneTaskInfo = action.payload.data
        },
        tableReducer(state, action) {
            state.table.result = action.payload.result;
        },
        oneTaskInfoStudentResult(state, action) {

            state.results.info = action.payload.result
            if (action.payload.result.status === 2) {
                state.results.info.data = [];
            }
        },
        clearTaskInfoStudentResult(state) {
            state.results.info.data = []
            state.results.info.message = ""
        },
        onPreloader(state) {
            state.preloader = true
        },
        offPreloader(state) {
            state.preloader = false
        }
    }
})

export const {
    oneTaskInfoStudentReducer,
    tableReducer,
    oneTaskInfoStudentResult,
    clearTaskInfoStudentResult,
    onPreloader,
    offPreloader
} = studentsTaskSlice.actions

export default studentsTaskSlice.reducer