import React, {useEffect, useRef, useState} from "react";
import styles from './createTasks.module.css';
import {useDispatch, useSelector} from "react-redux";
import {createMentorTasks} from "../../redux/tasksSlice";
import ModalTags from "../modalTags/modalTags";
import {getTags} from "../../redux/tagsSlice";
import AceEditor from "react-ace";
import {getLanguage} from "../../redux/languageGroupSlice";
import {toast} from "react-toastify";
import Title from "../title/Title";


const CreateTasks = () => {
    const form = useRef(null);


    const dispatch = useDispatch();
    const tokenState = useSelector(state => state.authorization.token);
    const getTagsList = useSelector(state => state.tags.tags);
    const languages = useSelector(state => state.languages.languages);
    const tasksValue = useSelector(state => state.mentorTasks.oneTaskInfo.task)


    const [modal, setModal] = useState(false);
    const [showContent, setShowContent] = useState(true);
    const [fileName, setFileName] = useState('')

    const [data, setData] = useState({
        title: tasksValue.title,
        order_num: tasksValue.order_num,
        description: tasksValue.description,
        project: tasksValue.project,
        language: tasksValue.language,
        sample_text: tasksValue.sample_text,
        test_cases: tasksValue.test_cases,
        points: tasksValue.points,
        theme: tasksValue.theme
    })


    const showTagModal = () => {
        setModal((prevState => !prevState));

    }
    const apply = () => {
        showTagModal();
    }

    useEffect(() => {
        if (tokenState.length) {
            dispatch(getTags(tokenState));
            dispatch(getLanguage(tokenState));
            setData({
                title: tasksValue.title,
                order_num: tasksValue.order_num,
                description: tasksValue.description,
                project: tasksValue.project,
                language: tasksValue.language,
                sample_text: tasksValue.sample_text,
                test_cases: tasksValue.test_cases,
                points: tasksValue.points,
                theme: tasksValue.theme
            })
        }
    }, [tokenState, tasksValue])

    const onChangeCheckbox = (e) => {
        const target = e.target
        const dataset = e.target.dataset;
        if (target.checked) {
            setData({
                ...data,
                theme: [...data.theme, parseInt(dataset.id)]
            })
        } else {
            let newArray = data.theme.filter(el => el !== +e.target.value)
            setData({
                ...data,
                theme: [...newArray]
            })
        }

    }

    const checkBox = (e) => {
        setShowContent(!showContent)
        setData({
            ...data,
            project: e.target.checked,
            test_cases: "",
            sample_text: ""
        })
    }

    const onChangeValue = (e) => {
        if (e.target.name === "order_num" || e.target.name === "points") {
            setData({
                ...data,
                [e.target.name]: +e.target.value
            })
        } else if (e.target.name === "language") {
            setData({
                ...data,
                [e.target.name]: +e.target.value
            });
        } else {
            setData({
                ...data,
                [e.target.name]: e.target.value
            })
        }
    }
    const onChangeUnit = (newValue) => {
        setData({
            ...data,
            test_cases: newValue
        })
    }
    const onChangeSample = (newValue) => {
        setData({
            ...data,
            sample_text: newValue
        })
    }

    const onChangeFileName = (e) => {
        setFileName(e.target.value)
    }

    const onSubmitData = (e) => {
        e.preventDefault()
        if (!data.description.trim()) {
            toast.error("заполните описание проекта")
            return
        } else if (!data.language) {
            toast.error("выберите язык программирования")
            return
        } else if (!data.points) {
            toast.error("заполните количество баллов")
            return
        } else if (!data.title.trim()) {
            toast.error("введите имя задачи")
            return
        } else if (data.theme.length < 1) {
            toast.error("выберите теги")
            return
        }

        const formData = new FormData(form.current);
        data.theme.forEach(el => formData.append('theme', el));
        dispatch(createMentorTasks({tokenState, data}));
    }

    return (
        <div className="container-fluid my-container-padding pt-5 pb-5">
            {showContent ? <Title title="Создать задачу"/> : <Title title="Создать проект"/> }

            <div className={styles.createTasksWrapper}>
                <form ref={form} onSubmit={onSubmitData}>
                    {
                        modal ? <ModalTags showModal={showTagModal} tagsArr={getTagsList} apply={apply}
                                           onChangeCheckbox={onChangeCheckbox} data={data}/> : ''
                    }

                    <div className="row">

                        <div className="col-lg-12 mb-4">
                            <label className="d-inline-flex align-items-center">
                                <div className={styles.projectCheck}>
                                    <input
                                        type="checkbox"
                                        className="me-2"
                                        onChange={checkBox}
                                        name="project"
                                        checked={data.project}
                                    />
                                    <span className={styles.without_tasks}>Проект</span>
                                </div>

                            </label>
                        </div>


                        <div className="col-lg-5">
                            <label className="w-100 mb-3">
                                <span className={styles.boldText}>Название задачи</span>
                                <input type="text" placeholder="Введите название задачи" className={styles.inputStyle}
                                       name="title"
                                       value={data.title}
                                       onChange={onChangeValue}/>
                            </label>

                            <label className="w-100 mb-3">
                                <span className={styles.boldText}>Колличество баллов</span>
                                <input type="number" placeholder="Введите колличество баллов" className={styles.inputStyle}
                                       name="points" value={data.points}
                                       onChange={onChangeValue}/>
                            </label>


                            <label className="w-100 mb-3">
                                <span className={styles.boldText}>Номер задачи</span>
                                <input type="number" placeholder="Введите номер задачи" className={styles.inputStyle}
                                       name="order_num"
                                       value={data.order_num}
                                       onChange={onChangeValue}/>
                            </label>


                            <label className="w-100 mb-3">
                                <span className={styles.boldText}>Выберите язык программирования</span>
                                <select name="language" onChange={onChangeValue} className={styles.inputStyle}>
                                    <option defaultValue="">Выбрать язык программирования</option>
                                    {languages.map((item) => {
                                        return (
                                            <option key={item.id} value={item.id}>{item.title}</option>)
                                    })}
                                </select>
                            </label>


                            <label className="w-100 mb-3">
                                <span className={styles.boldText}>Выберите тэги</span>
                                <div className={styles.tagBtn} onClick={showTagModal}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" color="white" height="24"
                                         viewBox="0 0 24 24"
                                         fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round">
                                        <line x1="3" y1="12" x2="21" y2="12"></line>
                                        <line x1="3" y1="6" x2="21" y2="6"></line>
                                        <line x1="3" y1="18" x2="21" y2="18"></line>
                                    </svg>
                                    <span className="ms-2">
                                    Добавить тэги
                            </span>
                                </div>
                            </label>
                        </div>


                        <div className="col-lg-7 mb-3">
                            <span className={styles.boldText}>{showContent ? 'Описание задач' : 'Описание проекта'}</span>
                            <textarea className={styles.textArea} name="description"
                                      value={data.description} onChange={onChangeValue}/>
                        </div>

                        <div className="col-lg-5 mb-3">
                            {showContent
                            &&
                            <>

                                <span className={styles.boldText}>Шаблоны</span>
                                <AceEditor
                                    className={styles.textArea}
                                    mode={data.language == 1 ? "python" : data.language == 3 ? "javascript" : ""}
                                    theme="github"
                                    fontSize="16px"
                                    value={data.sample_text}
                                    onChange={onChangeSample}
                                    name="sample_text"
                                />

                            </>
                            }
                        </div>


                        <div className="col-lg-7 mb-4">
                            {showContent
                            &&
                            <>
                                <span className={styles.boldText}>Unit тесты</span>
                                <AceEditor
                                    className={styles.textArea}
                                    mode={data.language == 1 ? "python" : data.language == 3 ? "javascript" : ""}
                                    theme="github"
                                    value={data.test_cases}
                                    fontSize="16px"
                                    onChange={onChangeUnit}
                                    name="test_cases"
                                />

                            </>
                            }
                        </div>


                        <div className="col-lg-12">
                            <div className="d-flex justify-content-end">
                                <button className={styles.saveBtn} type="submit">Сохранить</button>
                            </div>
                        </div>

                    </div>
                </form>
            </div>


        </div>
    )
}
export default CreateTasks;
