import React, {useEffect, useState} from "react";
import classes from './testPage.module.css';
import {NavLink, Route, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {deleteOneTest, getAllTestOfGroups} from "../../redux/testSlice";
import Test from "../../components/test/Test";
import ModalDelete from "../../components/modalDelete/ModalDelete";
import {testKey} from "../../redux/getTasksByStageSlice";
import Themes from "../../components/themes/Themes";
import {getTagsTests} from "../../redux/tagsSlice";
import {setDataInput} from "../../redux/dataInputSlice";


const TestsPage = () => {
    const dispatch = useDispatch();
    const tokenState = useSelector(state => state.authorization.token);
    const testsOfGroup = useSelector(state => state.tests.testOfGroup);
    const testNum = useSelector(state => state.allTasksByStage.testKey);
    const data = useSelector(state => state.dataInput.data);
    const languagesStore = JSON.parse(localStorage.getItem('languages'));



    const getTagsList = useSelector(state => state.tags.tagsTests);

    const [tests, setTests] = useState([]);

    const [active, setActive] = useState(false);



    const url = useLocation();

    const [deleteModal, setDeleteModal] = useState({
        status: false,
        id: null,
        title: ''
    });

    useEffect(() => {
        if (tokenState.length) {
            dispatch(getAllTestOfGroups({tokenState}))
            // dispatch(getTags(tokenState));

            if (testsOfGroup.length > 0) {
                setTests([...testsOfGroup[testNum].tests])
            }
        }
    }, [tokenState, testsOfGroup.length, testsOfGroup[testNum].tests.length]);


    const changeTest = (e) => {
        const key = +e.target.value
        dispatch(testKey(key));
        setTests([...testsOfGroup[key].tests])
    }

    const showDeleteModal = (e) => {
        const target = e.target
        e.preventDefault();
        setDeleteModal({
            status: !deleteModal.status,
            title: target.dataset.title,
            id: target.dataset.id
        });
    }

    const deleteTestBtn = (e) => {
        e.preventDefault();
        const target = e.target;
        const id = target.dataset.id
        if (tokenState.length) {
            dispatch(deleteOneTest({tokenState, id}));
            setDeleteModal({
                status: false,
                title: "",
                id: null
            })
        }
    }

    const changeComponent = (e) => {
        e.preventDefault();
        setActive(!active)
    }


    const changeTags = (e) => {
        const id = +e.target.value
        const value = +e.target.value
        const name = e.target.name

        dispatch(getTagsTests({tokenState, id}))

        dispatch(setDataInput({value, name}))
    }

    return (
        <>
            {deleteModal.status ? <ModalDelete showDeleteModal={showDeleteModal} deleteModal={deleteModal}
                                               deleteStageBtn={deleteTestBtn}/> : ''}
            <div className="container-fluid my-container-padding pt-5 pb-5">
                <div className={classes.testsPageWrapper}>
                    <div className={`row align-items-center ${classes.creteTestWrap}`}>
                        <div className="col-lg-2">
                            <h2>Тесты</h2>
                        </div>


                        <div className="col-lg-2 offset-lg-8">
                            <NavLink to="/createTests" className={classes.creteTest}>
                                Создать тест
                            </NavLink>
                        </div>
                    </div>

                    <div className={classes.navigation}>
                        <button className={active ? "" : "active"} onClick={changeComponent}>список тем</button>
                        <button className={active ? "active" : ""} onClick={changeComponent}>список тестов</button>
                    </div>

                    {
                        active
                            ?
                            <Test tests={tests} showDeleteModal={showDeleteModal} testsOfGroup={testsOfGroup}
                                  changeTest={changeTest} testNum={testNum}/>
                            :
                            <Themes getTagsList={getTagsList} url={url} languagesStore={languagesStore}
                                    changeTags={changeTags} data={data}/>
                    }
                </div>

                <div>

                </div>


            </div>
        </>

    )
}

export default TestsPage;






