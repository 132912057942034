import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {updateGroup, updateGroupPath} from "../../redux/mentorGroupSlice";
import classes from "./settings.module.css";
import {toast} from "react-toastify";
import {getTeachers} from "../../redux/teachersSlice";
import {getLanguage} from "../../redux/languageGroupSlice";
import CreateStage from "../createStage/CreateStage";
import ModalTasks from "../../components/modalTasks/ModalTasks";
import {getMentorTasksStage, themesNone} from "../../redux/tasksSlice";
import {
    createdStageReducer,
    createStagePost,
    deleteStage,
    getOneStage,
    getStage,
    updateOneStage
} from "../../redux/StageGroupSlice";
import StateInfo from "../stageInfo/StateInfo";
import ModalDelete from "../modalDelete/ModalDelete";
import StageTasksList from "../stageTasksList/StageTasksList";
import ModalTasksUpdate from "../modalTasksUpdate/ModalTasksUpdate";
import {setDataInput} from "../../redux/dataInputSlice";
import Title from "../title/Title";


const SettingsGroup = () => {
    let today = new Date().toISOString().slice(0, 10);

    const {id} = useParams();
    const dispatch = useDispatch();

    const itemsRef = useRef([]);

    const colorRef = useRef([])


    const tokenState = useSelector(state => state.authorization.token);
    const groupInfo = useSelector(state => state.groupsInfo.groupInfo);
    const teachers = useSelector(state => state.teachers.teachers);
    const languages = useSelector(state => state.languages.languages);
    const value = useSelector(state => state.dataInput.data)


    const stagesGroup = useSelector(state => state.stagesGroup.groupStage);

    const stageOne = useSelector(state => state.stagesGroup.oneStage)

    const createdStage = useSelector(state => state.stagesGroup.createdStage)


    const languagesStore = JSON.parse(localStorage.getItem('languages'));


    const mentorTasks = useSelector(state => state.mentorTasks.tasksStage)

    const [points, setPoints] = useState(0);

    const [stageData, setStageData] = useState({
        id_group: +id,
        title: "",
        duration: "",
        date_after: "",
        pass_grade: points,
        order_num: "",
        condition: "",
        tasks: []
    })

    const [groupValue, setGroupValue] = useState({
        end_date: "",
        language: "",
        mentor: "",
        title: ""
    })

    const [modal, setModal] = useState(false);

    const [updateModal, setUpdateModal] = useState(false);

    const [deleteModal, setDeleteModal] = useState({
        status: false,
        id: null,
        title: ''
    });

    const [stageOneInfo, setStageOneInfo] = useState({});


    useEffect(() => {
        if (tokenState.length) {
            dispatch(updateGroup({tokenState, id}))
            dispatch(getTeachers(tokenState));
            dispatch(getLanguage(tokenState));
            dispatch(getStage({tokenState, id}));



            setGroupValue({
                end_date: groupInfo.end_date,
                language: groupInfo.language,
                mentor: groupInfo.mentor,
                title: groupInfo.title
            })

            setStageOneInfo({
                condition: stageOne.condition,
                date_after: stageOne.date_after,
                duration: stageOne.duration,
                id: stageOne.id,
                order_num: stageOne.order_num,
                pass_grade: stageOne.pass_grade,
                tasks: stageOne.tasks,
                title: stageOne.title

            })

            itemsRef.current = itemsRef.current.slice(0, stagesGroup.length);
            colorRef.current = colorRef.current.slice(0, stagesGroup.length);
        }
    }, [tokenState, groupInfo.language, stageOne.id, stagesGroup.length])

    const onChangeInput = (e) => {
        e.preventDefault();
        const target = e.target;
        if (target.name === "mentor" || target.name === "language") {
            setGroupValue({
                ...groupValue,
                [target.name]: +target.value
            })
        } else {
            setGroupValue({
                ...groupValue,
                [target.name]: target.value
            })
        }

    }

    const onChangeInputCreate = (e) => {
        e.preventDefault();
        const target = e.target;
        if (target.name === "title" || target.name === "date_after") {
            setStageData({
                ...stageData,
                [target.name]: target.value,

            })
        } else {
            setStageData({
                ...stageData,
                [target.name]: +target.value,
            })
        }
    }

    const showModal = () => {
        setModal(!modal);
    }

    const showUpdateModal = () => {
        setUpdateModal(!updateModal)
    }

    const submit = () => {
        for (const name in groupValue) {
            if (!groupValue[name]) {
                toast.error('заполните все поля!');
                return;
            }
        }
        dispatch(updateGroupPath({tokenState, groupValue, id}))
    }

    const onChangeCheckbox = (e) => {
        const target = e.target
        const dataset = e.target.dataset;

        if (target.checked) {
            setStageData({
                ...stageData,
                tasks: [...stageData.tasks, parseInt(dataset.id)],
                pass_grade: points + +dataset.points
            })
            setPoints(points + +dataset.points)
        } else {
            let newArray = stageData.tasks.filter(el => el !== +e.target.value)
            setStageData({
                ...stageData,
                tasks: [...newArray],
                pass_grade: points - +dataset.points
            })
            setPoints(points - +dataset.points)
        }
    }

    const onChangeCheckboxUpdate = (e) => {
        const target = e.target;
        const dataset = target.dataset;
        if (target.checked) {
            setStageOneInfo({
                ...stageOneInfo,
                tasks: [...stageOneInfo.tasks, parseInt(dataset.id)],
                pass_grade: stageOneInfo.pass_grade + +dataset.points
            })
        } else {
            let newArray = stageOneInfo.tasks.filter(el => el !== +e.target.value)
            setStageOneInfo({
                ...stageOneInfo,
                tasks: [...newArray],
                pass_grade: stageOneInfo.pass_grade - +dataset.points
            })
        }

    }

    const onChangeOneStageInfo = (e) => {
        e.preventDefault();
        const target = e.target;
        if (target.name === "title" || target.name === "date_after") {
            setStageOneInfo({
                ...stageOneInfo,
                [target.name]: target.value
            })
        } else {
            setStageOneInfo({
                ...stageOneInfo,
                [target.name]: +target.value
            })
        }
    }

    const apply = () => {
        showModal()
    }

    const showDeleteModal = (e) => {
        const target = e.target
        e.preventDefault();
        setDeleteModal({
            status: !deleteModal.status,
            title: target.dataset.title,
            id: +target.dataset.id
        });
    }

    const createStageBtn = (e) => {
        e.preventDefault()
        const {title} = stageData
        const {tasks} = stageData

        for (const name in stageData) {
            if (!stageData[name] || !title.trim() || tasks.length === 0) {
                toast.error('заполните все поля при создании этапа');
                return;
            }
        }
        dispatch(createStagePost({tokenState, stageData, id}))
    }

    const updateStageBtn = (e) => {
        e.preventDefault()
        const id = e.target.dataset.id_group;
        const {title} = stageOneInfo
        const {tasks} = stageOneInfo
        for (const name in stageOneInfo) {
            if (!stageOneInfo[name] || !title.trim() || tasks.length === 0) {
                toast.error('заполните все поля при измении этапа');
                return;
            }
        }

        dispatch(updateOneStage({tokenState, stageOneInfo, id}));

    }

    const deleteStageBtn = (e) => {
        e.preventDefault();
        const target = e.target;
        const idStage = +target.dataset.id
        if (tokenState.length) {
            dispatch(deleteStage({tokenState, idStage, id}));
            setDeleteModal({
                status: false,
                title: "",
                id: null
            })
        }
    }

    const getTasksList = (e) => {
        e.preventDefault();
        const target = e.target;
        const id = +target.dataset.id;
        const newArr = stagesGroup.filter(el => el.stage.id === +target.dataset.id);

        dispatch(createdStageReducer(newArr[0].stage.tasks))


        if (colorRef.current[e.target.dataset.key].classList.contains('active')) {
            colorRef.current.map((el) => {
                el.classList.remove('active');
                dispatch(createdStageReducer([]))
            })
        } else {
            colorRef.current.map((el, key, arr) => {
                el.classList.remove('active');
                arr[e.target.dataset.key].classList.add('active');

            })
        }

        if (itemsRef.current[e.target.dataset.key].style.display === "block") {
            itemsRef.current.map((el) => {
                el.style.display = "none";
            });
        } else if (itemsRef.current[e.target.dataset.key].style.display === "none") {
            itemsRef.current.map((el, key, arr) => {
                el.style.display = "none";
                arr[e.target.dataset.key].style.display = "block"
            });
        }

        if (tokenState.length) {
            dispatch(getOneStage({tokenState, id}))
        }
    }

    const changeLanguageFilter = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        const language = e.target.value
        if (language === "") {
            dispatch(setDataInput({name, value}))
            dispatch(themesNone())
            return
        }


        dispatch(setDataInput({name, value}))
        dispatch(getMentorTasksStage({tokenState, language}))
    }


    return (
        <>
            {modal ? <ModalTasks modalState={showModal}
                                 mentorTasks={mentorTasks}
                                 onChangeCheckbox={onChangeCheckbox}
                                 apply={apply}
                                 stageData={stageData}
                                 points={points}
                                 languagesStore={languagesStore}
                                 changeLanguageFilter={changeLanguageFilter}
                                 value={value}
            /> : ''}

            {deleteModal.status ? <ModalDelete showDeleteModal={showDeleteModal} deleteModal={deleteModal}
                                               deleteStageBtn={deleteStageBtn}/> : ''}

            {updateModal ? <ModalTasksUpdate mentorTasks={mentorTasks}
                                             showUpdateModal={showUpdateModal}
                                             stageOneInfo={stageOneInfo}
                                             onChangeCheckboxUpdate={onChangeCheckboxUpdate}
                                             points={stageOneInfo.pass_grade}
                                             languagesStore={languagesStore}
                                             changeLanguageFilter={changeLanguageFilter}
                                             value={value}
                />

                : ''}

            <div className="container-fluid my-container-padding pt-5 pb-5">
                <Title title="Редактирование группы"/>
                <div className={classes.wrapperSettingsGroups}>
                    <div className="row">
                        <div className="col-lg-4 pe-3">
                            <div className={classes.groupInfo}>
                                <h3 className={classes.title}>
                                    Настройка группы
                                </h3>
                                <div className={classes.groupInfo_item}>
                                    <span>Группа</span>
                                    <input type="text"
                                           value={groupValue.title === null ? '' : groupValue.title}
                                           onChange={onChangeInput}
                                           name="title"/>
                                </div>

                                <div className={classes.groupInfo_item}>
                                    <span>Ментор</span>
                                    <select
                                        value={groupValue.mentor === null ? '' : groupValue.mentor}
                                        onChange={onChangeInput} name="mentor">
                                        {teachers.map((teacher, key) => <option key={key}
                                                                                value={teacher.id}>{teacher.full_name}</option>)}

                                    </select>
                                </div>
                                <div className={classes.groupInfo_item}>
                                    <span>Языки</span>
                                    <select
                                        value={groupValue.language === null ? '' : groupValue.language}
                                        onChange={onChangeInput} name="language">
                                        {languages.map((language, key) => <option key={key}
                                                                                  value={language.id}>{language.title}</option>)}
                                    </select>
                                </div>
                                <div className={classes.groupInfo_item}>
                                    <span>Дата окончания:</span>
                                    <input
                                        value={groupValue.end_date} type="date" min={today} onChange={onChangeInput}
                                        name="end_date"/>
                                </div>
                                <button onClick={submit} className={classes.submitBtn}>Изменить</button>
                            </div>

                            <CreateStage id_group={id} modalState={showModal} onChangeInputCreate={onChangeInputCreate}
                                         createStageBtn={createStageBtn} stageData={stageData} today={today}
                                         points={points}/>

                        </div>
                        <div className="col-lg-4">
                            <StateInfo stagesGroup={stagesGroup}
                                       showDeleteModal={showDeleteModal}
                                       getTasksList={getTasksList}
                                       itemsRef={itemsRef}
                                       colorRef={colorRef}
                                       today={today}
                                       stageOneInfo={stageOneInfo}
                                       onChangeOneStageInfo={onChangeOneStageInfo}
                                       updateStageBtn={updateStageBtn}
                                       id_group={id}
                                       showUpdateModal={showUpdateModal}
                            />
                        </div>
                        <div className="col-lg-4">
                            <StageTasksList stageList={createdStage}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SettingsGroup;