import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {API, BASE_URL} from "../api/api";
import {clearDataInput} from "./dataInputSlice";
import {toast} from "react-toastify";



export const sqlTableSlice = createAsyncThunk(
    "sqlTable",
    async (data, {rejectWithValue, dispatch}) => {

        try {
            let options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                },
                body: JSON.stringify(data.data)
            }
            const response = await fetch(`${BASE_URL}${API.mentor.make_query}`, options);

            if (response.ok) {
                const table = await response.json();
                dispatch(getResultTable(table));

                const name = "query";
                dispatch(clearDataInput({name}));

                toast.success("таблица успешно создана")
            }
            else {
                const message = await response.json();
                dispatch(setError(message.message))
            }


        } catch (error) {
            return rejectWithValue(error.message)
        }
    })


const sqlResultSlice = createSlice({
    name: "sqlResult",
    initialState: {
        result: [],
        message: ""
    },
    reducers: {
        getResultTable(state, action) {
            state.result = action.payload.result
            state.message = action.payload.message
        },
        setError(state, action) {
            state.result = []
            state.message = action.payload
        },
        clearError (state, action) {
            state.message = ""
        }
    }
})

export const {getResultTable, setError, clearError} = sqlResultSlice.actions;

export default sqlResultSlice.reducer;


