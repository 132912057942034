import React, {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import classes from "./studentTestOne.module.css";
import {useDispatch, useSelector} from "react-redux";
import {getOneTest, hideModalReducer, sendAnswers} from "../../redux/testStudentSlice";
import {Form} from "react-bootstrap";
import ModalResultTest from "../modalResultTest/ModalResultTest";
import radio from '../../images/radio.svg'
import checkbox from '../../images/checkbox.svg'
import {toast} from "react-toastify";


const StudentTestOne = () => {
    const tokenState = useSelector(state => state.authorization.token2);
    const testOneInfo = useSelector(state => state.studentTests.oneStudentTest);
    const oneResultTest = useSelector(state => state.studentTests.oneResultTest);
    const modal = useSelector(state => state.studentTests.modal)

    const {id} = useParams();
    let url = useLocation();
    const mainId = url.state.id;
    const [duration, setDuration] = useState({m: url.state.duration, s: 0})


    const dispatch = useDispatch();
    const [data, setData] = useState({
        answeredQuestions: [],
        testId: +id
    });

    const tick = () => {
        if (duration.s <= 0) {
            setDuration({m: duration.m - 1, s: 59})
        } else {
            setDuration({m: duration.m, s: duration.s - 1})
        }
    }

    useEffect(() => {
        if (tokenState.length) {
            dispatch(getOneTest({id, tokenState}))
            toast.success("Тест начался, следите за таймером!", 4000)
        }
    }, [tokenState])

    useEffect(() => {
        if(duration.m <= 0 & duration.s <= 0) {
            toast.error("Время вышло")
            dispatch(sendAnswers({tokenState, data, mainId}))
            setDuration({m: 0, s: 0});
            return;
        }
        const intervalId = setInterval(() => tick(), 1000);
        return () => clearInterval(intervalId)
    }, [duration.s])

    const onChangeAnswer = (e) => {
        const target = e.target;
        if (target.dataset.name === "radio") {
            const index = data.answeredQuestions.findIndex(obj => obj.questionId == +target.dataset.questionid);
            const checked = index < 0 ? false : true;
            if (checked) {
                data.answeredQuestions.map((el, key) => {
                    if (el.questionId === +target.dataset.questionid) {
                        data.answeredQuestions.splice(key, 1)
                    }
                })

            }
            setData({
                ...data,
                answeredQuestions: [...data.answeredQuestions, {
                    answerId: +target.dataset.answerid,
                    questionId: +target.dataset.questionid,
                    subjectId: +target.dataset.subjectid
                }]
            })
        } else if (target.dataset.name === "checkbox") {
            if (e.target.checked) {
                setData({
                    ...data,
                    answeredQuestions: [...data.answeredQuestions, {
                        answerId: +target.dataset.answerid,
                        questionId: +target.dataset.questionid,
                        subjectId: +target.dataset.subjectid
                    }]
                })
            } else {
                let newArray = data.answeredQuestions.filter(el => el.answerId !== +target.dataset.answerid);

                setData({
                    ...data,
                    answeredQuestions: [...newArray]
                })
            }
        }
    }


    const hideModal = () => {
        dispatch(hideModalReducer())
    }

    const submit = (e) => {
        e.preventDefault()
        dispatch(sendAnswers({tokenState, data, mainId}))
    }

    return (

        <>
            {modal && <ModalResultTest hideModal={hideModal} oneResultTest={oneResultTest} closeBtn={false}/>}
            <div className="container-fluid my-container-student pb-5">

                <div className={classes.title}>
                    <h2>Тест - {testOneInfo.title}</h2>
                    <span>{duration.m < 10 ? 0 : ""}{duration.m} : {duration.s < 10 ? 0 : ""}{duration.s}</span>
                </div>

                {testOneInfo.questions.length > 0 &&
                <div className="mb-4">
                    <span className={`d-inline-flex align-items-center me-4 ${classes.hint}`}><img src={radio}
                                                                                                   alt={radio}
                                                                                                   className="me-2"/>В тесте только один ответ</span>
                    <span className={`d-inline-flex align-items-center ${classes.hint}`}><img src={checkbox}
                                                                                              alt={checkbox}
                                                                                              className="me-2"/>В тесте имеется два ответа</span>
                </div>
                }

                <Form className={classes.answers}>
                    {
                        testOneInfo.questions.length > 0 ?
                            testOneInfo.questions.map((question, key) => {
                                let type = '';
                                if (question.title === 1) {
                                    type = "radio"
                                } else if (question.title >= 2) {
                                    type = "checkbox"
                                }
                                return (
                                    <div key={key} className={classes.questionWrap}>

                                        {question.image === "" || question.image === null || question.image === "string" ? <h4>{question.question}</h4> :
                                            <img src={question.image} alt={question.image} className={classes.questionImage}/>
                                        }

                                        {question.answers.map((answer, key) => {
                                            return (
                                                <Form.Check
                                                    key={key}
                                                    label={answer.answer}
                                                    name={`group-${question.id}`}
                                                    type={type}
                                                    id={answer.id}

                                                    data-answerid={answer.id}
                                                    data-questionid={question.id}
                                                    data-subjectid={question.subjectId}
                                                    data-name={type}
                                                    onChange={onChangeAnswer}
                                                    className={classes.checkFrom}
                                                />
                                            )
                                        })}
                                    </div>
                                )
                            })
                            :
                            <div className="fs-5">Тестов пока нет</div>
                    }
                    {testOneInfo.questions.length > 0 &&
                    <button type="submit" className={classes.submitBtn} onClick={submit}>отправить</button>}
                </Form>

            </div>
        </>

    )
}

export default StudentTestOne;