import React, {useEffect, useState} from "react";
import classes from './createTest.module.css'
import {useDispatch, useSelector} from "react-redux";
import {getGroupsForCreateTests} from "../../redux/mentorGroupSlice";
import {getThemes, getThemesReducer} from "../../redux/themeSlice";
import {toast} from "react-toastify";
import {createTest, createTestFinish, getTestLanguage} from "../../redux/testSlice";


const CreateTest = () => {

    const tokenState = useSelector(state => state.authorization.token);
    const groups = useSelector(state => state.groupsInfo.groupsForTests);
    const themes = useSelector(state => state.themes.themes);
    const tests = useSelector(state => state.tests)

    const [checkBox, setCheckBox] = useState([]);

    const [data, setData] = useState({
        langId: "",
        subjects: [],
        title: ""
    })

    const [createData, setCreateData] = useState({
            id: null,
            test_name: "",
            start_date: "",
            end_date: "",
            count_question: "",
            duration: 0,
            group: ""
        }
    )


    const dispatch = useDispatch();

    useEffect(() => {
        if (tokenState.length) {
            dispatch(getTestLanguage(tokenState));
            dispatch(getGroupsForCreateTests({tokenState}))


            setCreateData({
                ...createData,
                id: tests.responseFirst.id,
                test_name: tests.responseFirst.title,
                count_question: tests.responseFirst.questionsCount
            })
        }
    }, [tokenState, tests.status])


    useEffect(() => {
        const themes = []

        setCheckBox([])
        dispatch(getThemesReducer({themes}));
    }, [tokenState])

    const onChange = (e) => {
        e.preventDefault();
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
        if (e.target.name === "langId") {
            setData({
                ...data,
                [e.target.name]: +e.target.value
            })

            if (data.langId !== 0) {
                let id = e.target.value
                dispatch(getThemes({tokenState, id}))
            }
        }

    }

    const onChangeCreateData = (e) => {
        const target = e.target;
        if (target.name === "duration" || target.name === "group") {
            setCreateData({
                ...createData,
                [target.name]: +target.value
            })
        } else {
            setCreateData({
                ...createData,
                [target.name]: target.value
            })
        }
    }

    const onCheckBoxChange = (e) => {
        const target = e.target
        if (target.checked) {
            setCheckBox([...checkBox, +target.dataset.id])
        } else {
            data.subjects.forEach((el, key) => {
                if (el.subjectId === +target.dataset.id) {
                    data.subjects.splice(key, 1)
                }
            })

            let newArray = checkBox.filter(el => el !== +target.dataset.id)
            setCheckBox([...newArray])
        }
    }

    const changeCount = (e) => {
        const target = e.target

        if (+target.value <= +target.dataset.amount) {
            const index = data.subjects.findIndex(obj => obj.subjectId == +target.dataset.id)
            const checked = index < 0 ? true : false;
            if (checked) {
                setData({
                    ...data,
                    subjects: [...data.subjects, {subjectId: +target.dataset.id, question_count: +target.value}]
                })
            } else {
                data.subjects.forEach((el, key, arr) => {
                    if (el.subjectId === +target.dataset.id) {
                        data.subjects.splice(key, 1)
                    }
                })

                setData({
                    ...data,
                    subjects: [...data.subjects, {subjectId: +target.dataset.id, question_count: +target.value}]
                })
            }
        } else {
            toast.error(`Неправильное количество вопросов, вопросов должно быть ${target.dataset.amount}`)
        }
    }


    const firstSubmit = () => {
        if(!data.title.trim() || data.langId == "" || data.subjects.length == 0) {
            toast.error("Заполните все поля");
            return;
        }
        dispatch(createTest({tokenState, data}))
    }

    const onSubmit = () => {
        if(createData.group == "" || createData.duration <= 0 || createData.start_date == "" || createData.end_date == "") {
            toast.error("Заполните все поля");
            return;
        }

        dispatch(createTestFinish({tokenState, createData}));

        setCheckBox([])

        setData({
            langId: "",
            subjects: [],
            title: ""
        })

        setCreateData({
            id: null,
            test_name: "",
            start_date: "",
            end_date: "",
            count_question: "",
            duration: 0,
            group: ""
        })

    }


    return (
        <div className="container-fluid my-container-padding pt-5 pb-5">
            <div className={classes.createTestsWrapper}>
                <h2>Создать тест</h2>
                <div className={`row ${classes.createTestsForm} d-flex`}>
                    <h3 className={classes.step}>ШАГ 1 - Выберите название язык и темы</h3>
                    <div className="col-lg-5">

                        <div className="mb-5">
                            <label className={classes.label}>
                                <span>Название теста</span>
                                <input type="text" placeholder="Введите название теста" onChange={onChange}
                                       name="title" value={data.title}/>
                            </label>
                        </div>
                        <div className="mb-5">
                            <label className={classes.label}>
                                <span>Выбрать язык</span>
                                <select name="langId" onChange={onChange} value={data.langId}>
                                    <option value="">Выбрать язык</option>
                                    {tests.languages.map((language, key) => <option key={key}
                                                                                    value={language.id}>{language.name}</option>)}
                                </select>
                            </label>
                        </div>

                    </div>
                    <div className="col-lg-6 offset-lg-1">
                        <div>
                            <h4>Выберите темы</h4>
                            <div className={classes.checkboxes}>
                                {
                                    themes.length > 0
                                        ?
                                        themes.map((theme, key) => {
                                            const index = checkBox.indexOf(+theme.id)
                                            const checked = index < 0 ? false : true;
                                            return (
                                                <div className={classes.checkbox} key={key}>
                                                    <label className="checkContainer mb-0 w-50 fs-6">
                                                        {theme.title}
                                                        <input type="checkbox" value={theme.id} data-id={theme.id}
                                                               checked={checked} onChange={onCheckBoxChange}/>
                                                        <span className="checkmark" data-id={theme.id}/>
                                                    </label>
                                                    <span>доступно: {theme.questionsAmount} вопросов</span>
                                                    <label className={classes.labelThemes}>
                                                        <input type="number" onBlur={changeCount} data-id={theme.id}
                                                               data-amount={theme.questionsAmount}
                                                               disabled={!checked} max={theme.questionsAmount}/>
                                                    </label>

                                                </div>
                                            )
                                        })
                                        :
                                        <span>Нет доступных тем</span>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="text-end">
                        <button className={`d-block ms-auto ${classes.submitBtn}`} onClick={firstSubmit}>применить
                        </button>
                    </div>


                    <div className="col-lg-5">
                        <h3 className={tests.status ? classes.step : classes.stepDisabled}>ШАГ 2 - заполните поля и
                            сохраните тест</h3>
                        <div className="mb-5">
                            <label className={tests.status ? classes.label : classes.labelDisabled}>
                                <span>Выбрать группу</span>
                                <select name="group" onChange={onChangeCreateData} disabled={!tests.status}
                                        value={createData.group}>
                                    <option value="">Выбрать группу</option>
                                    {groups.map((group, key) => <option key={key}
                                                                        value={group.id}>{group.title}</option>)}
                                </select>
                            </label>
                        </div>

                        <div className="mb-5">
                            <label className={tests.status ? classes.label : classes.labelDisabled}>
                                <span>Продолжительность теста</span>
                                <input type="number" placeholder="15" onChange={onChangeCreateData}
                                       disabled={!tests.status}
                                       name="duration"
                                       value={createData.duration === 0 ? "" : createData.duration}
                                />
                            </label>
                        </div>

                        <div className="mb-5">
                            <div className={tests.status ? classes.label : classes.labelDisabled}>
                                <span>Период проведения теста</span>
                            </div>
                            <div className="d-flex">
                                <label className={`${tests.status ? classes.label : classes.labelDisabled} me-3`}>
                                    <span>от</span>
                                    <input type="date" placeholder="15" onChange={onChangeCreateData}
                                           disabled={!tests.status}
                                           name="start_date"
                                           value={createData.start_date}
                                    />
                                </label>
                                <label className={tests.status ? classes.label : classes.labelDisabled}>
                                    <span>до</span>
                                    <input type="date" placeholder="15" onChange={onChangeCreateData}
                                           disabled={!tests.status}
                                           name="end_date"
                                           value={createData.end_date}
                                    />
                                </label>
                            </div>

                        </div>
                    </div>


                    <div className="text-end">
                        <button disabled={!tests.status} onClick={onSubmit}
                                className={tests.status ? classes.submitBtn : classes.submitBtnDisabled}>сохранить
                        </button>
                    </div>


                </div>
            </div>
        </div>
    )

}

export default CreateTest