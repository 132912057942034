import {Outlet} from "react-router-dom";
import React from "react";
import StudentMenu from "../studentMenu/StudentMenu";



const StudentLayout  = () => {

    return (
        <>
            <StudentMenu/>
            <Outlet/>
        </>

    )

}
export default StudentLayout;