import React, {useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import Title from "../title/Title";
import classes from "./createQuestions.module.css";
import {useDispatch, useSelector} from "react-redux";
import {
    addAnswer,
    changeAnswerStatus,
    changeAnswerTitle,
    clearAnswers,
    createQuestionPost,
    deleteAnswerCase, deletePicture, getOneQuestion,
    getQuestions, updateQuestionOff
} from "../../redux/questionsSlice";
import {clearDataInput, setDataInput} from "../../redux/dataInputSlice";
import {Form} from "react-bootstrap";
import del from "../../images/deleteSvg.svg"
import delWhite from "../../images/deleteWhite.svg"
import picture from "../../images/picture.svg"
import {toast} from "react-toastify";


const CreateQuestion = ({}) => {
        const dispatch = useDispatch();
        const {id} = useParams();
        const tokenState = useSelector(state => state.authorization.token);

        const colorRef = useRef([])

        const data = useSelector(state => state.dataInput.data)

        const questions = useSelector(state => state.questionsReducer.questions)

        const error = useSelector(state => state.questionsReducer.error)

        const answers = useSelector(state => state.questionsReducer.answers)
        const update = useSelector(state => state.questionsReducer.update)
        const image = useSelector(state => state.questionsReducer.image)

        useEffect(() => {
            dispatch(updateQuestionOff())
            dispatch(clearAnswers())
            if (tokenState.length) {
                dispatch(getQuestions({tokenState, id}))
            }
        }, [tokenState])


        const onChangeInput = (e) => {
            const data = {
                name: e.target.name,
                value: e.target.value
            }
            dispatch(setDataInput(data))
        }

        const createAnswer = () => {

            if (data.answer === undefined || !data.answer.trim()) {
                toast.error("Заполните поле добавления ответа");
                return
            }
            dispatch(addAnswer(data.answer));
            dispatch(clearDataInput({name: "answer"}))
        }

        const deleteAnswer = (e) => {
            dispatch(deleteAnswerCase(e.target.dataset.key))
        }

        const changeAnswerStatusFunc = (e) => {
            dispatch(changeAnswerStatus(e.target.dataset.key))
        }

        const changeAnswerTitleFunc = (e) => {
            const data = {
                key: e.target.dataset.key,
                title: e.target.value
            }

            dispatch(changeAnswerTitle(data))
        }


        const createQuestion = (e) => {
            e.preventDefault();
            const type = e.target.dataset.action;
            let questionInfo = {
                question: data.question,
                answers: [...answers],
                image: image,
                subjectId: +id
            }

            if (type === "update") {
                questionInfo = {...questionInfo, questionId: data.questionId}
            }

            if (questionInfo.question === undefined || !questionInfo.question.trim()) {
                toast.error("Напишите свой вопрос")
                return;
            } else if (questionInfo.answers.length < 2) {
                toast.error("Минимальное количество ответов 2")
                return;
            }


            if (data.file) {
                questionInfo = {...questionInfo, image: ""}
                const formData = new FormData();
                formData.append("file", data.file);
                formData.append("fileName", data.file.name);

                dispatch(createQuestionPost({tokenState, questionInfo, formData, type}))
                colorRef.current.map(el => el.classList.remove('active'))

                return
            }

            dispatch(createQuestionPost({tokenState, questionInfo, type}))
            colorRef.current.map(el => el.classList.remove('active'))

        }

        const changeInputFile = (e) => {
            const data = {
                name: e.target.name,
                value: e.target.files[0]
            }

            dispatch(setDataInput(data))
        }

        const getOneQuestionInfo = (e) => {
            const {id, key} = e.target.dataset;
            const data = {
                name: "questionId",
                value: id
            }

            colorRef.current.map(el => el && el.classList.remove('active'))
            colorRef.current[key].classList.add('active');


            dispatch(setDataInput(data))
            dispatch(getOneQuestion({tokenState, id}))
        }

        const cancelUpdate = (e) => {
            for (let key in data) {
                if (key === "question" || key === "questionId")
                    dispatch(clearDataInput({name: key}))
            }
            colorRef.current.map(el => el && el.classList.remove('active'))
            dispatch(clearAnswers())
            dispatch(updateQuestionOff())
        }


        const deletePictureFunc = (e) => {
            dispatch(clearDataInput({name: "file"}))
            dispatch(deletePicture())
        }


        return (
            <div className="container-fluid my-container-padding pt-5 pb-5 ">
                <Title title={update ? "Редактировать вопрос" : "Добавить вопрос"}/>
                <div className={` row ${classes.createThemeForm}`}>
                    <div className="col-lg-5">
                        <label className="mb-5">
                            <span>Вопрос</span>
                            <input
                                type="text"
                                placeholder="Добавьте вопрос"
                                name="question"
                                onChange={onChangeInput}
                                value={data.question ? data.question : ""}
                            />
                        </label>

                        {

                            update
                                ?
                                image === "string" || image === ""
                                    ?
                                    ""
                                    :
                                    <>
                                        <div className={`${classes.imageWrap} mb-5`}>
                                            <img src={image} alt={image}/>
                                            <button onClick={deletePictureFunc}>
                                                <img src={delWhite} alt={delWhite}/>
                                            </button>
                                        </div>

                                    </>
                                :
                                ""

                        }


                        <label className="mb-5">
                            <span>{update ? "Редактировать картинку" : "Добавить картинку"}</span>
                            <div className={classes.fileWrapper}>
                                <img src={picture} alt=""/>
                                {data.file ? data.file.name : update ? "Редактировать картинку" : "Добавить картинку"}
                            </div>

                            <input type="file" name="file" onChange={changeInputFile} className={classes.file}
                                   accept="image/*"/>
                        </label>


                        <label className="mb-5">
                            <span>Добавить ответ</span>
                            <div className={classes.answer}>
                                <input
                                    type="text"
                                    placeholder="Добавить вариант ответа"
                                    name="answer"
                                    value={data.answer ? data.answer : ""}
                                    onChange={onChangeInput}
                                />
                                <button onClick={createAnswer}>+</button>
                            </div>
                        </label>

                        {
                            answers.length > 0
                            &&
                            <label className="mb-5">
                                <span>Варианты ответов</span>
                                {
                                    answers.map((answer, key) => (
                                        <div className={classes.oneAnswer} key={key}>
                                            <div className={classes.answerList} key={key}>
                                                <input
                                                    type="text"
                                                    value={answer.answer}
                                                    onChange={changeAnswerTitleFunc}
                                                    data-key={key}
                                                />

                                                <button onClick={deleteAnswer} data-key={key}>
                                                    <img src={del} alt={del}/>
                                                </button>
                                            </div>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label="Правильный ответ"
                                                data-key={key}
                                                onChange={changeAnswerStatusFunc}
                                                checked={answer.true}
                                            />
                                        </div>
                                    ))}
                            </label>
                        }

                        <div className="text-end">

                            {
                                update
                                &&
                                <button className={classes.cancelBtn} onClick={cancelUpdate}>
                                    Отменить редактирование
                                </button>
                            }
                            <button
                                onClick={createQuestion}
                                className={classes.createBtn}
                                data-action={update ? "update" : "create"}
                            >
                                {update
                                    ?
                                    "Редактировать"
                                    :
                                    "Создать"
                                }
                            </button>
                        </div>


                    </div>


                    <div className="col-lg-6 offset-lg-1">
                        <h4 className={classes.title}>Список вопросов</h4>
                        <ul className={classes.questions}>
                            {
                                questions.length > 0
                                    ?
                                    questions.map((question, key) => (
                                        <li className={classes.question}
                                            key={question.id}
                                            data-id={question.id}
                                            onClick={getOneQuestionInfo}
                                            ref={el => colorRef.current[key] = el}
                                            data-key={key}
                                        >
                                            <span data-id={question.id}>{key + 1}</span>{question.question}
                                        </li>
                                    ))
                                    :
                                    <li className={classes.question}>
                                        {error}
                                    </li>
                            }
                        </ul>
                    </div>

                </div>
            </div>


        )
    }

;

export default CreateQuestion;