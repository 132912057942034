import {createSlice} from "@reduxjs/toolkit";



const ErrorSlice = createSlice({
    name: 'error',
    initialState: {
        errorStatus: false,
    },
    reducers: {
        showError(state){
            state.errorStatus = true;
        },
        hideError(state) {
            state.errorStatus = false;
        }
    }
})


export const {showError, hideError} = ErrorSlice.actions;

export default ErrorSlice.reducer