import React from "react";
import classes from "./log.module.css"
import {Link} from "react-router-dom";


const Log = ({reports, onChangeInput, onChangeSearch}) => {

    return (reports.length > 0 ?
            <div className={classes.logTable}>
                <ul className={classes.logTableHeader}>
                    <li className={classes.search_wrap}>
                        <input type="search" placeholder="поиск" className={classes.btnSearch}
                               onChange={onChangeSearch}/>
                    </li>
                    <li className={classes.headTable}>
                        Общая сумма баллов
                    </li>
                    {
                        reports[0].tasks.map((val, key) =>
                            <li className={classes.headTable} key={key}>
                                {val.task}
                            </li>
                        )

                    }
                </ul>
                {
                    reports.map((item, key) =>
                        <ul className={classes.logTableHeader} key={key}>
                            <li>
                                {item.student__full_name}
                            </li>
                            <li className={classes.grade}>
                                <span>
                                    {item.result ? item.result : 0} из {item.pass_grade}
                                </span>

                            </li>
                            {item.tasks.map((val, key2) => {
                                return (
                                    <li className={val.count <= 0 ? classes.liOfNamesNone : classes.liOfNames}
                                        key={key2}>
                                        <input type="number" onChange={onChangeInput} data-id={val.id}
                                               data-stageid={item.id} value={val.count === null ? 0 : val.count}
                                               style={{width: '70px'}}/>

                                        <Link to={`/taskInfoMentor/${val.id}`}
                                              state={{count: val.count, pass_id: val.id}}
                                              className={classes.showTaskBtn}>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </Link>
                                    </li>
                                )

                            })}
                        </ul>
                    )
                }
            </div> : <div className={classes.logTable}>
                <ul className={classes.logTableHeader}>
                    <li className={classes.search_wrap}>
                        <input type="search" placeholder="поиск" className={classes.btnSearchForNo}
                               onChange={onChangeSearch}/>
                    </li>
                </ul>


                <ul className={classes.logTableHeader}>
                    <li>
                        Студент не найден!
                    </li>
                </ul>
            </div>

    )

}

export default Log;