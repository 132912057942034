import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {toast} from "react-toastify";


// const loginUrl = 'https://app1.megacom.kg:9090/task-manager/api/v1/accounts/login/';

const loginUrl = 'https://app1.megacom.kg:9090/python-staging/task-manager/api/v1/accounts/login/';


// const loginUrl = 'http://10.242.147.40:8000/api/v1/accounts/login/'

// const loginUrl = 'http://10.242.147.8:6060/api/v1/accounts/login/'



export const fetchLogin = createAsyncThunk(
    "authorization/fetchLogin",
    async function(userLogin, {rejectWithValue, dispatch}){
        try {
            let options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",

                },
                body: JSON.stringify(userLogin.loginState)
            }
            const response = await fetch(`${loginUrl}`, options);
            if (response.ok) {
                const data = await response.json();
                if (data[1].role === 1) {
                    dispatch(tokenLocal(data))
                    dispatch(getLanguageAdmin(data[1].language))

                    userLogin.navigation("/mentorPage");
                } else if (data[1].role === 2) {
                    dispatch(tokenLocal2(data))
                    userLogin.navigation("/taskList")
                }
            } else {
                toast.error("Неверно введено пароль или логин")
            }
        } catch (error){
            return rejectWithValue(error.message)
        }
    }
)

const authorizationSlice = createSlice({
    name: "authorization",
    initialState: {
        token: [],
        token2: [],
        login:{
            username: "",
            password: ""
        },
        language: []
    },
    reducers: {
        loginHandler(state, action){

            let keys = Object.keys(action.payload);
            let text = action.payload[keys[0]];
            state.login[keys[0]] = text;
        },
        tokenLocal(state, action){
            state.token = action.payload;
            localStorage.setItem(`token`, JSON.stringify(action.payload));
        },
        tokenLocal2(state, action){
            state.token2 = action.payload;
            localStorage.setItem(`token`, JSON.stringify(action.payload));
        },
        getLanguageAdmin(state, action) {
            state.language = action.payload;
            localStorage.setItem(`languages`, JSON.stringify(action.payload));
        }
    }
})

export const {loginHandler, tokenLocal, tokenLocal2, getLanguageAdmin} = authorizationSlice.actions;

export default authorizationSlice.reducer;