import {Outlet} from "react-router-dom";
import Menu from "../menu/Menu";
import React from "react";



const Layout  = () => {

    return (
        <>
            <Menu/>
            <Outlet/>


        </>

    )

}
export default Layout;