// export const BASE_URL = 'https://app1.megacom.kg:9090/task-manager'
// export const BASE_URL_SECOND = 'https://app1.megacom.kg:9090/task-manager-test' //продовый для тестов



export const BASE_URL = 'https://app1.megacom.kg:9090/python-staging/task-manager' // тестовый сервак
export const BASE_URL_SECOND = 'https://app1.megacom.kg:9090/task-manager-service/test' //  тестовый второй

// export const BASE_URL = 'http://10.242.147.8:6060'
// export const BASE_URL = 'http://10.242.147.40:8000' // Назгуль тестовый


// export const BASE_URL_SECOND = 'http://10.242.147.40:8095' // Санжар тестовый


export  const API = {
    mentor: {
        getGroups: '/api/v1/group/create/',
        updateGroup: '/api/v1/group/update/',
        getTeachers: '/api/v1/accounts/teachers/',
        getLanguage: '/api/v1/languages/',
        getMentorTasks: '/api/v1/tasks/task/',
        createStage: '/api/v1/stage/create/',
        getGroupStage: '/api/v1/stage/group-stage/list/',
        StageGroup: '/api/v1/stage/update/',
        getTag: '/api/v1/themes/',
        tagsForTest: '/api/v1/subject/list',
        themes: '/api/v1/themes/',
        createTest: '/api/v1/test/create/',
        themesList: '/api/v1/subject/list/',
        studentTask: '/api/v1/tasks/student-task/',
        test_of_group: '/api/v1/test/tests/',
        delete_test: '/api/v1/test/',
        groups_for_tests: '/api/v1/group/groups/',
        make_query: '/api/v1/sql/make-query/',
        students_visits: '/api/v1/report/all-students/attendance/',

        create_date_log: "/api/v1/report/attend/",
        delete_visits_date: "/api/v1/report/date/delete/",
        update_visits_date:"/api/v1/report/date/update/",

        task_by_theme: '/api/v1/tasks/task-by-theme/',
        get_questions : '/api/v1/question/list',
        save_question: '/api/v1/question/save',
        update_question: '/api/v1/question/update',
        add_img_to_question: '/api/v1/question/upload/img',
        get_question_by_id: '/api/v1/question/getById'

    },
    student:{
        getStage: '/api/v1/stage/student-stage/',
        getTasksByStage: '/api/v1/tasks/grouped-tasks/',
        getReportByStage: '/api/v1/report/stage/',
        getTests: '/api/v1/test/students/',
        oneTest: '/api/v1/test/get/',
        checkTest: '/api/v1/test/check/',
        getResultTest: '/api/v1/test/student/',
        changePassCount: '/api/v1/tasks/student-task/'
    }
}

