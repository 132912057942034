import React, {useEffect, useState} from "react";
import classes from "./createTheme.module.css";
import {useDispatch, useSelector} from "react-redux";
import {getLanguage} from "../../redux/languageGroupSlice";
import {toast} from "react-toastify";
import {createTheme} from "../../redux/themeSlice";


const CreateThemePage = () => {
    const tokenState = useSelector(state => state.authorization.token);
    const languages = useSelector(state => state.languages.languages);
    const themeValues= useSelector(state=>state.themes.oneTheme)
    const dispatch = useDispatch()


    const [data, setData] = useState({
        title:themeValues.title,
        description: themeValues.description,
        language: themeValues.language
    })


    useEffect(() => {
        if(tokenState.length) {
            dispatch(getLanguage(tokenState));
            setData({
                title:themeValues.title,
                description: themeValues.description,
                language: themeValues.language
            })
        }
    },[tokenState,themeValues])


    const onChangeValue = (e) => {
        e.preventDefault();
        e.target.name === "language"
            ?
            setData({
                ...data,
                [e.target.name]: +e.target.value
            })
            :
            setData({
                ...data,
                [e.target.name]: e.target.value
            })
    }

    const setTheme = () => {
        if(!data.title.trim() || !data.language) {
            toast.error('Заполните обязательные поля');
            return
        }
        dispatch(createTheme({tokenState, data}));
    }


    return (
        <div className="container-fluid my-container-padding pt-5 pb-5">
            <div className={classes.wrapper}>
                <h2>Создание темы</h2>
                <div className={`row ${classes.createThemeForm} d-flex flex-column`}>
                    <div className="col-lg-4 mb-5">
                        <label>
                            <span>Укажите тему</span>
                            <input type="text" name="title" onChange={onChangeValue} value={data.title}/>
                        </label>
                    </div>

                    <div className="col-lg-4 mb-5">
                        <label>
                            <span>Краткое описание</span>
                            <textarea name="description" onChange={onChangeValue} value={data.description}/>
                        </label>
                    </div>

                    <div className="col-lg-4 mb-5">
                        <label>
                            <span>Выбрать язык</span>
                            <select name="language" onChange={onChangeValue} value={data.language}>
                                <option value="">Выбрать язык</option>
                                {languages.map((language, key) => <option key={key}
                                                                          value={language.id}>{language.title}</option>)}
                            </select>
                        </label>
                    </div>
                    <div className="w-100 text-end">
                        <button className={classes.btn} onClick={setTheme}>Создать</button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default CreateThemePage;