import React from "react";
import classes from "./modalResultTest.module.css";
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import Preloader from "../preloader/Preloader";


const ModalResultTest = ({hideModal, oneResultTest, closeBtn}) => {

    const preloader = useSelector(state => state.preloader.preloader)

    return (
        <div className={classes.wrap}>
            <div className={classes.inner}>
                {preloader
                    ?
                    <Preloader/>
                    :
                    <>
                        {closeBtn && <button onClick={hideModal} className={classes.closeBtn}>закрыть</button>}
                        <>
                            <h5>Название теста</h5>
                            <h2>{oneResultTest.test_name}</h2>
                            <p>Общий результат:</p>
                            {oneResultTest.result === null || oneResultTest.grade === null
                                ? <h3>Вы еще не сдали этот тест</h3>
                                :
                                <>
                                    <h3>Вы ответили правильно на {oneResultTest.grade} / {oneResultTest.total}</h3>
                                    <ul>
                                        <h4>темы</h4>
                                        {oneResultTest.result.map((el, key) => <li
                                            key={key}>{el.name}: <strong>{el.correctQuestions} / {el.totalQuestionsBySubject}</strong>
                                        </li>)}
                                    </ul>
                                </>
                            }
                        </>

                        {!closeBtn
                        &&
                        <div className={classes.link}>
                            <NavLink to="/testStudentPage" className={classes.goToTest} onClick={hideModal}>вернуться к
                                тестам</NavLink>
                        </div>
                        }
                    </>
                }


            </div>
        </div>
    )
}

export default ModalResultTest;