import {createAsyncThunk,createSlice} from "@reduxjs/toolkit";
import {API, BASE_URL} from "../api/api";



export const  getTeachers= createAsyncThunk(
    'getTeachers',
    async (token,{rejectWithValue, dispatch}) => {
        try {
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${token[0].auth_token}`
                }
            }
            const response = await fetch(`${BASE_URL}${API.mentor.getTeachers}`, options);
            if(response.ok) {
                const data = await response.json();
                dispatch(getTeachersReducer({data}));

            }
        }
        catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

const teachersSlice = createSlice({
    name: 'teachers',
    initialState: {
        teachers: []
    },
    reducers: {
        getTeachersReducer(state, action){
            state.teachers = action.payload.data
        }
    }
})


export const {getTeachersReducer} = teachersSlice.actions

export default teachersSlice.reducer