import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {API, BASE_URL} from "../api/api";
import {toast} from "react-toastify";
import {showCalendarModal, showDelModal} from "./deleteModalSlice";


export const getStudentsVisits = createAsyncThunk(
    "studentsVisits",
    async function (data, {rejectWithValue, dispatch}) {
        try {
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                }
            }
            const response = await fetch(`${BASE_URL}${API.mentor.students_visits}${data.id}`, options);
            if (response.ok) {
                const data = await response.json();
                dispatch(studentVisitsInfo({data}))
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const createDateThunk = createAsyncThunk(
    "createDate",
    async function (data, {rejectWithValue, dispatch}) {
        try {
            let options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                },
                body: JSON.stringify(data.data)
            }
            const response = await fetch(`${BASE_URL}${API.mentor.create_date_log}`, options);
            if (response.ok) {
                toast.success("Дата добавлена");
                const {tokenState} = data;
                const id = data.data.group

                dispatch(getStudentsVisits({tokenState, id}))

            } else {
                toast.error("Дата не добавлена")
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)


export const changeVisits = createAsyncThunk(
    "changeVisits",
    async function (data, {rejectWithValue, dispatch}) {
        try {
            let options = {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                },
                body: JSON.stringify(data.data)
            }
            const response = await fetch(`${BASE_URL}${API.mentor.create_date_log}${data.info.id}`, options);
            if (response.ok) {
                const {tokenState} = data;
                const id = data.info.group;
                dispatch(getStudentsVisits({tokenState, id}))
                toast.success("Данные обработаны успешно", {autoClose: 500})
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const deleteDate = createAsyncThunk(
    'deleteDate',
    async function (data, {rejectWithValue, dispatch}) {
        try {
            let options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                },
                body: JSON.stringify(data.data)
            }
            const response = await fetch(`${BASE_URL}${API.mentor.delete_visits_date}`, options);
            if (response.ok) {
                const {tokenState} = data;
                const id = data.data.group;
                dispatch(getStudentsVisits({tokenState, id}))
                toast.success("Данные  успешно удалены", {autoClose: 2000})
                dispatch(showDelModal())
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)


export const updateDate = createAsyncThunk(
    'updateDate',
    async function (data, {rejectWithValue, dispatch}) {
        try {
            let options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                },
                body: JSON.stringify(data.data)
            }
            const response = await fetch(`${BASE_URL}${API.mentor.update_visits_date}`, options);
            if (response.ok) {
                const {tokenState} = data;
                const id = data.data.group;
                dispatch(getStudentsVisits({tokenState, id}))
                toast.success("Данные  успешно изменены", {autoClose: 2000})
                dispatch(showCalendarModal())
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)





const studentsSlice = createSlice({
    name: "studentsSlice",
    initialState: {
        visits: [],
        date: ""
    },
    reducers: {
        studentVisitsInfo(state, action) {
            state.visits = action.payload.data
        },
        setDateDelete(state, action) {
            state.date = action.payload
        }
    }
})

export const {studentVisitsInfo, setDateDelete} = studentsSlice.actions

export default studentsSlice.reducer