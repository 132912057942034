import React, {useEffect, useState} from 'react';
import classes from "../createLanguage/createLanguage.module.css";
import Title from "../title/Title";
import AceEditorComponent from "../aceEditorComponent/AceEditorComponent";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {clearError, sqlTableSlice} from "../../redux/sqlTableSlice";
import {Table} from "react-bootstrap";
import {setDataInput} from "../../redux/dataInputSlice";

const CreateSqlTable = () => {
    const tokenState = useSelector(state => state.authorization.token);
    const result = useSelector(state => state.sql.result)
    const message = useSelector(state => state.sql.message)

    const data = useSelector(state => state.dataInput.data)

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(clearError());
    }, [])


    const onChange = (e) => {
        const name = "query";
        const value = e;
        dispatch(setDataInput({name, value}))
    }

    const onSubmit = () => {
        const {query} = data
        if (!query.trim()) {
            toast.error("Заполните редактор кода")
            return
        }
        dispatch(sqlTableSlice({data, tokenState}));
    }

    return (
        <div className="container-fluid my-container-padding pt-5">
            <div className={classes.wrapper}>
                <Title title="Создание sql таблицы"/>
                <div className={`row ${classes.createThemeForm} d-flex flex-column`}>

                    <div className="col-lg-12 mb-5">
                        <AceEditorComponent value={data.query} onChange={onChange} language="sql"/>
                    </div>

                    <div className="w-100 text-end mb-5">
                        <button className={classes.btn} onClick={onSubmit}>Создать sql таблицы</button>
                    </div>


                    <div className="col-lg-12 ">

                        {
                            result === null

                                ?
                                <p className="fs-5">
                                    Таблица пуста
                                </p>
                                :

                                result.length > 0
                                &&
                                <Table striped bordered hover className="mb-5">
                                    <thead>
                                    <tr>
                                        {
                                            Object.keys(result[0]).map((el, key) => <th key={key}>{el}</th>)
                                        }
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {
                                        result.map((elem, key) => {
                                            return (
                                                <tr key={key}>
                                                    {Object.keys(result[0]).map((el, key) => <td
                                                        key={key}>{elem[el]}</td>)}
                                                </tr>
                                            )
                                        })
                                    }

                                    </tbody>
                                </Table>
                        }
                        {
                            message
                            &&
                            <p className="text-danger fs-5">
                                Результат: {message}
                            </p>}

                    </div>


                </div>
            </div>
        </div>
    );
};

export default CreateSqlTable;