import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {API, BASE_URL} from "../api/api";
import {toast} from "react-toastify";

export const getGroups = createAsyncThunk(
    'getGroups',
     async (data, {rejectWithValue, dispatch}) => {
        try {
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                }
            }
            const response = await fetch(`${BASE_URL}${API.mentor.getGroups}?language=${data.language}&page=${data.pageNumber}&start_date=${data.time}`, options);

            if(response.ok) {
                const data = await response.json();
                dispatch(getGroup({data}))
            }
        }
        catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const getGroupsForCreateTests = createAsyncThunk(
    'getGroupsForCreateTests',
    async (data, {rejectWithValue, dispatch}) => {

            try {
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                }
            }
            const response = await fetch(`${BASE_URL}${API.mentor.groups_for_tests}`, options);
            if(response.ok) {
                const data = await response.json();
                dispatch(getGroupsForTests({data}))
            }
        }
        catch (error) {
            return rejectWithValue(error.message)
        }
    }
)



export const updateGroup = createAsyncThunk(
    'updateGroup',
    async (data, {rejectWithValue, dispatch}) => {
        try {
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                }
            }
            const response = await fetch(`${BASE_URL}${API.mentor.updateGroup}${data.id}/`, options);
            if(response.ok) {
                const data = await response.json();

                dispatch(groupInfo({data}))
            }
        }
        catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const updateGroupPath = createAsyncThunk(
    'updateGroupPath',
    async (data, {rejectWithValue, dispatch})  => {
        try {
            let options = {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                },
                body: JSON.stringify(data.groupValue)
            }
            const response = await fetch(`${BASE_URL}${API.mentor.updateGroup}${+data.id}/`, options);
            if(response.ok) {
                toast.success("Данные успешно обновлены")
            }

        }catch (error) {
            return rejectWithValue(error.message)
        }
    }

)
const groupInfoSlice= createSlice({
    name: "groupsInfo",
    initialState: {
        groupsStatus: false,

        groupsForTests: [],
        groups: [],
        groupInfo: {
            end_date: "",
            language: "",
            mentor: "",
            title: ""
        },
        paginationCount: 0,
    },
    reducers: {
        getGroup(state, action){
            state.groupsStatus = true;
            state.groups = action.payload.data.results;
            state.paginationCount = action.payload.data.count
        },
        groupInfo(state, action){
            state.groupInfo.chat_id = action.payload.data.chat_id
            state.groupInfo.end_date = action.payload.data.end_date
            state.groupInfo.language = action.payload.data.language
            state.groupInfo.mentor = action.payload.data.mentor
            state.groupInfo.title = action.payload.data.title
        },
        getGroupsForTests(state,action) {
            state.groupsForTests = action.payload.data
        }
    }
})

export const {getGroup, groupInfo, getGroupsForTests} = groupInfoSlice.actions

export default groupInfoSlice.reducer