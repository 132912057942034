import React from "react";
import AceEditorComponent from "../../components/aceEditorComponent/AceEditorComponent";
import Title from "../../components/title/Title";
import style from './taskExecutionForSql.module.css'
const TaskExecutionForSql = () => {
return(
    <div className="my-container-padding pt-5 pb-5">
                <div className="row d-flex align-items-start">

                    <div className="col-lg-6 mb-4">
                      <Title title="Инструкции"/>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur dolorum eaque enim magni nam odio quisquam sapiente voluptate? Ad corporis cumque ducimus illo laboriosam nisi, quaerat ratione repellendus sapiente vero?
                        </p>
                        <button className={style.showAnswerBtn}>
                            Показать правильный результат
                        </button>

                        <div>
                            <table className="table mt-5">
                                <thead className={style.tableThead}>
                                <tr>
                                    <td>id</td>
                                    <td>name</td>
                                    <td>surname</td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Abai</td>
                                    <td>Karagulov</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Dastan</td>
                                    <td>Abil'daev</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-4">
                        <Title title="Решение"/>
                        <AceEditorComponent value="asdasd"/>
                    </div>
                </div>

                <div className="row d-flex align-items-center">
                    <div className="col-lg-6 mb-4">
                    </div>

                    <div className="col-lg-6 mb-4">

                        <div>
                            <button className={style.resetBtn}>
                                Сбросить
                            </button>
                            <button className={style.sendBtn}>
                                Отправить
                            </button>
                        </div>
                        <div>
                            <h2>Результат:</h2>
                            <span className="text-success">Выполнено</span>
                        </div>
                            <div>
                                <table className="table mt-3">
                                    <thead className={style.tableThead}>
                                    <tr>
                                        <td>id</td>
                                        <td>name</td>
                                        <td>surname</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Abai</td>
                                            <td>Karagulov</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>Dastan</td>
                                            <td>Abil'daev</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                </div>

        </div>
)

}
export default TaskExecutionForSql