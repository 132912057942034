import React, {useEffect, useState} from "react";
import Title from "../title/Title";
import {useLocation, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getStudentTaskById} from "../../redux/tasksSlice";
import classes from "./taskInfoMentor.module.css";
import AceEditorComponent from "../aceEditorComponent/AceEditorComponent";
import {changePassCountFromLog} from "../../redux/reportSlice";
import Preloader from "../preloader/Preloader";


const TaskInfoMentor = () => {
    const tokenState = useSelector(state => state.authorization.token);
    const infoStudentTask = useSelector(state => state.mentorTasks.oneTaskInfo);

    const preloader = useSelector(state => state.preloader.preloader)
    const {id} = useParams()
    const dispatch = useDispatch();
    const url = useLocation();

    const [taskInfo, setTaskInfo] = useState({
        pass_count: url.state.count
    })

    const onChange = (e) => {
        setTaskInfo({
            pass_count: +e.target.value
        })
    }

    const onClickPassCount = () => {
        const pass_count = taskInfo.pass_count;
        dispatch(changePassCountFromLog({id, tokenState, pass_count}))
    }

    useEffect(() => {
        if (tokenState.length) {
            dispatch(getStudentTaskById({tokenState, id}))
        }
    }, [tokenState])

    return (
        <div className="my-container-padding pt-5 pb-5">
            {preloader
                ? <Preloader/>
                :
                <div className="row d-flex align-items-start">
                    <div className="col-lg-6 mb-4">
                        <Title title="Инструкции"/>
                        <div className={classes.info__wrapper}>
                            {infoStudentTask.task.description}
                        </div>
                    </div>

                    <div className="col-lg-6 mb-4">
                        <Title title="Решение"/>
                        <AceEditorComponent value={infoStudentTask.solution} language="python"/>
                    </div>

                    <div className="col-lg-6 mb-4">
                        <div className={classes.info__tags}>
                            Теги: {infoStudentTask.task.theme.map((el, key) => <span key={key}>#{el.title}</span>)}
                        </div>
                    </div>

                    <div className="col-lg-6 d-flex justify-content-end align-items-center mb-5">
                        <span className={classes.info_pass_countText}>Выставить баллы:</span>
                        <input type="text" value={taskInfo.pass_count} className={classes.info_pass_count}
                               onChange={onChange}/>
                    </div>

                    <div className="col-lg-6 offset-lg-6 d-flex justify-content-end align-items-center">
                        <button className={classes.info_send_btn} onClick={onClickPassCount}>Поставить оценку</button>
                    </div>

                </div>}


        </div>
    )
}

export default TaskInfoMentor