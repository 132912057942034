import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {API, BASE_URL} from "../api/api";
import {hidePreloader, showPreloader} from "./preloaderSlice";



export const getAllStage = createAsyncThunk(
    'getAllStage',
    async (token, {rejectWithValue, dispatch}) => {

        try {
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${token[0].auth_token}`
                }
            }

            dispatch(showPreloader())
            const response = await fetch(`${BASE_URL}${API.student.getStage}`, options);
            if (response.ok) {
                const data = await response.json();
                dispatch(getAllStageReducer({data}))
                dispatch(hidePreloader())

            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
);



const getAllStageSlice = createSlice({
        name: 'allStage',
        initialState: {
            allStageList: [],
        },
        reducers: {
            getAllStageReducer(state, action) {
                state.allStageList = action.payload.data
            }

        }
    }
)

export const {getAllStageReducer} = getAllStageSlice.actions

export default getAllStageSlice.reducer