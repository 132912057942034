import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {API, BASE_URL, BASE_URL_SECOND} from "../api/api";
import {toast} from "react-toastify";
import {getThemesReducer} from "./themeSlice";

export const getTestLanguage = createAsyncThunk(
    'getTestLanguage',
    async (token, {rejectWithValue, dispatch}) => {
        try {
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${token[0].auth_token}`
                }
            }
            const response = await fetch(`${BASE_URL_SECOND}/api/v1/lang/list/`, options);
            if (response.ok) {
                const data = await response.json();
                dispatch(testLanguage({data}))
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)


export const createTest = createAsyncThunk(
    'createTest',
    async (data, {rejectWithValue, dispatch}) => {
        try {
            let options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                },
                body: JSON.stringify(data.data)
            }
            const response = await fetch(`${BASE_URL_SECOND}/api/v1/test/create/`, options);


            if (response.ok) {
                const data = await response.json();
                toast.success("Данные приняты")
                dispatch(firstRequestGet({data}))
            } else {

            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const createTestFinish = createAsyncThunk(
    'createTestFinish',
    async (data, {rejectWithValue, dispatch}) => {
        try {
            let options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                },
                body: JSON.stringify(data.createData)
            }
            const response = await fetch(`${BASE_URL}${API.mentor.createTest}`, options);
            if (response.ok) {
                dispatch(firstRequestGet({}))
                const themes = []
                dispatch(getThemesReducer({themes}));
                toast.success("Тест успешно создан")
            } else {
                toast.error("Произошла ошибка при создании теста")
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)


export const getAllTestOfGroups = createAsyncThunk(
    'getAllTestOfGroups',
    async (data, {rejectWithValue, dispatch}) => {
        try {
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                },
            }
            const response = await fetch(`${BASE_URL}${API.mentor.test_of_group}`, options);
            if (response.ok) {
                const data = await response.json();
                dispatch(testOfGroup({data}))
            } else {
                toast.error("Произошла ошибка при получении данных")
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }

)


export const deleteOneTest = createAsyncThunk(
    'deleteOneTest',
    async (data, {rejectWithValue, dispatch}) => {

        try {
            let options = {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                },
            }
            const response = await fetch(`${BASE_URL}${API.mentor.delete_test}${data.id}/`, options);
            if (response.ok) {
                const tokenState = data.tokenState
                dispatch(getAllTestOfGroups({tokenState}))

                toast.success("Тест успешно удален")
            } else {

            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)




const testInfoSlice = createSlice({
    name: 'testInfo',
    initialState: {
        status: false,
        responseFirst: {
            id: null,
            questions: null,
            questionsCount: null,
            title: ''
        },
        languages: [],
        testOfGroup: [{tests: []}],
        data: {
            langId: "",
            subjects: [],
            title: ""
        }
    },
    reducers: {
        firstRequestGet(state, action) {
            state.status = !state.status
            state.responseFirst = {...action.payload.data}
        },
        testLanguage(state, action) {
            state.languages = action.payload.data
        },
        testOfGroup(state, action) {
            if(action.payload.data.length > 0) {
                state.testOfGroup = action.payload.data
            }else {
                state.testOfGroup = [{tests: []}]
            }


        }
    }
})


export const {firstRequestGet, testLanguage, testOfGroup} = testInfoSlice.actions;

export default testInfoSlice.reducer;
