import React from "react";
import classes from "./stateInfo.module.css";
import del from '../../images/deleteSvg.svg'
import download from "../../images/download.svg";


const StateInfo = ({
                       stagesGroup,
                       showDeleteModal,
                       getTasksList,
                       itemsRef,
                       today,
                       colorRef,
                       stageOneInfo,
                       onChangeOneStageInfo,
                       updateStageBtn,
                       id_group,
                       showUpdateModal
                   }) => {

    return (
        <div className={classes.stateInfo__wrapper}>
            <h5 className={classes.title}>Действующие этапы</h5>
            <ul className={classes.stateInfo__stages}>
                {stagesGroup.length > 0
                    ?
                    stagesGroup.map((el, key) =>
                        <div key={key}>
                            <li>
                                <button className={classes.stageInfo_title} onClick={getTasksList}
                                        data-id={el.stage.id} data-key={key} ref={el => colorRef.current[key] = el}>
                                    {el.stage.title}
                                </button>
                                <button className={classes.deleteBtn} onClick={showDeleteModal}
                                        data-title={el.stage.title} data-id={el.stage.id}>
                                    <img src={del} alt={del} data-title={el.stage.title} data-id={el.stage.id}/>
                                </button>
                            </li>

                            <div key={key} ref={el => itemsRef.current[key] = el} style={{display: "none"}}>
                                <div className={classes.editStage__inputs}>
                                    <div className={classes.createStage__inputs_item}>
                                        <strong>Название этапа:</strong>
                                        <input type="text" placeholder="Название этапа" name="title"
                                               value={stageOneInfo.title} onChange={onChangeOneStageInfo}/>
                                    </div>

                                    <div className={classes.createStage__inputs_item}>
                                        <strong>Кол-во дней:</strong>
                                        <input type="number" placeholder="Кол-во дней" name="duration"
                                               value={stageOneInfo.duration} min="1" onChange={onChangeOneStageInfo}/>
                                    </div>

                                    <div className={classes.createStage__inputs_item}>
                                        <strong>Начало этапа:</strong>
                                        <input type="date" name="date_after" min={today} value={stageOneInfo.date_after}
                                               onChange={onChangeOneStageInfo}/>
                                    </div>

                                    <div className={classes.createStage__inputs_item}>
                                        <strong>Оценка за этап:</strong>
                                        <input type="number" name="pass_grade" placeholder="100" min="0"
                                               value={stageOneInfo.pass_grade} disabled />
                                    </div>

                                    <div className={classes.createStage__inputs_item}>
                                        <strong>Порядок этапа:</strong>
                                        <input type="number" name="order_num" placeholder="1" min="1"
                                               value={stageOneInfo.order_num} onChange={onChangeOneStageInfo}/>
                                    </div>

                                    <div className={classes.createStage__inputs_item}>
                                        <strong>Статус этапа:</strong>
                                        <select name="condition" value={stageOneInfo.condition}
                                                onChange={onChangeOneStageInfo}>
                                            <option value="">выбрать</option>
                                            <option value="1">активный</option>
                                            <option value="2">закрытый</option>
                                            <option value="3">ждет очереди</option>
                                        </select>
                                    </div>

                                    <div className={classes.createStage__inputs_item}>
                                        <strong>Загрузка:</strong>
                                        <button onClick={showUpdateModal}><img src={download} alt={download}/>Загрузка задач</button>
                                    </div>
                                    <button className={classes.submitBtn}  data-id_group={id_group}  data-id={el.stage.id} onClick={updateStageBtn}>Соxранить</button>

                                </div>

                            </div>


                        </div>
                    )
                    :
                    <li className={classes.stateInfo__noStage}>
                        Этапы не были добавлены
                    </li>
                }
            </ul>

        </div>

    )
}


export default StateInfo;