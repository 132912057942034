import React from "react";
import classes from '../modalTasks/modalTasks.module.css'
import {Form} from "react-bootstrap";


const ModalTasksUpdate = ({
                              mentorTasks,
                              showUpdateModal,
                              stageOneInfo,
                              onChangeCheckboxUpdate,
                              points,
                              languagesStore,
                              changeLanguageFilter,
                              value
                          }) => {

    return (
        <div className="main_modal">
            <div className="modal_my" onClick={showUpdateModal}>
            </div>
            <div className={classes.modal_wrapper}>

                <div className="d-flex align-items-center justify-content-between">
                    <h3>Список задач</h3>
                    <h5 className={classes.points}>Оценка за этап: {points}</h5>
                </div>

                <Form.Select className="w-100 p-2 mb-4" style={{border: "1px solid #ccc", borderRadius: "8px"}}
                             onChange={changeLanguageFilter} name="filterLanguages" value={value.filterLanguages}>
                    <option value="">Выберите язык</option>
                    {languagesStore.map((el, key) => <option key={key} value={el.id}>{el.title}</option>)}
                </Form.Select>

                <div className={classes.checkboxes}>
                    {
                        mentorTasks.length > 0
                            ?
                            mentorTasks.map((theme, key) => {
                                return (
                                    <div key={key} className={classes.theme}>
                                        <h5 className="mb-3 ">Тема: {theme.theme}</h5>
                                        {theme.tasks.map((task, key) => {
                                            let checked = stageOneInfo.tasks.indexOf(task.id) < 0 ? false : true
                                            return (
                                                <div className={classes.checkbox} key={key}>
                                                    <label className="checkContainer">
                                                        {task.title}
                                                        <input type="checkbox" onChange={onChangeCheckboxUpdate}
                                                               checked={checked} data-id={task.id}
                                                               data-points={task.points} value={task.id}/>
                                                        <span className="checkmark" data-id={task.id}
                                                              data-points={task.points}/>
                                                    </label>
                                                </div>
                                            )

                                        })}
                                    </div>

                                )
                            })
                            :
                            value.filterLanguages
                                ?
                                <h5 className="mb-4">Список задач пуст </h5>
                                :
                                ""


                    }
                </div>
                <button className={classes.applyBtn} onClick={showUpdateModal}>Применить</button>
            </div>
        </div>
    )
}

export default ModalTasksUpdate;