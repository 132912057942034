import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {API, BASE_URL} from "../api/api";
import {toast} from "react-toastify";

export const getLanguage = createAsyncThunk(
    'getLanguage',
    async (token, {rejectWithValue, dispatch}) => {
        try {
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${token[0].auth_token}`
                }
            }
            const response = await fetch(`${BASE_URL}${API.mentor.getLanguage}`, options);
            if (response.ok) {
                const data = await response.json();
                dispatch(getLanguageReducer({data}))


            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const createLanguage = createAsyncThunk(
    'createLanguage',
    async (data, {rejectWithValue, dispatch}) => {
        try {
            let options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                },
                body: JSON.stringify(data.data)
            }
            const response = await fetch(`${BASE_URL}${API.mentor.getLanguage}`, options);
            if (response.ok) {
                const data = await response.json();
                toast.success('Язык успешно создан', 6000);
                dispatch(clearLanguageReducer())
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)


const languageSlice = createSlice({
        name: 'language',
        initialState: {
            languages: [],
            clearLanguage: {
                title:''
            }
        },
        reducers: {
            getLanguageReducer(state, action) {
                state.languages = action.payload.data
            },
            clearLanguageReducer(state){
                state.clearLanguage={
                    title:''
                }
            }

        }
    }
)


export const {getLanguageReducer,clearLanguageReducer} = languageSlice.actions

export default languageSlice.reducer