import React from "react";
import Title from "../../title/Title";
import classes from "../taskExecutionnew.module.css";
import AceEditorComponent from "../../aceEditorComponent/AceEditorComponent";
import {Link} from "react-router-dom";
import {Table} from "react-bootstrap";

const OneExecution = ({
                          infoStudentTask,
                          studentAnswer,
                          nextUrl,
                          reset,
                          submitSolution,
                          onChange,
                          showRightResult,
                          table,
                          results,
                          placePreloader

                      }) => {
    return (
        <>
            <div className="row d-flex align-items-start">
                <div className="col-lg-6 mb-4">
                    <Title title="Инструкции"/>
                    <div className={classes.info__wrapper}>

                        <pre>{infoStudentTask.task.description}</pre>

                        {
                            infoStudentTask.task.language.title === "sql"
                            &&
                            <button className={classes.showResultBtn} onClick={showRightResult}>показать правильный
                                результат</button>
                        }


                        {
                            table.result.length > 0
                            &&
                            <Table striped bordered hover className="mb-0">
                                <thead>
                                <tr>
                                    {
                                        Object.keys(table.result[0]).map((el, key) => <th key={key}>{el}</th>)
                                    }
                                </tr>
                                </thead>
                                <tbody>

                                {
                                    table.result.map((elem, key) => {
                                        return (
                                            <tr key={key}>
                                                {Object.keys(table.result[0]).map((el, key) => <td
                                                    key={key}>{elem[el]}</td>)}
                                            </tr>
                                        )
                                    })
                                }

                                </tbody>
                            </Table>
                        }
                    </div>

                    <span className={classes.pass_count}>
                            Количество попыток: {infoStudentTask.pass_count}
                        </span>

                </div>
                <div className="col-lg-6 mb-4">
                    <Title title="Решение"/>
                    <AceEditorComponent
                        value={studentAnswer.solution
                            ? studentAnswer.solution
                            : studentAnswer.sample_text}
                        onChange={onChange}
                        language={infoStudentTask.task.language.title.toLowerCase()}
                    />
                </div>
            </div>


            <div className="row d-flex align-items-center">
                <div className="col-lg-6 mb-4">
                    <div className={classes.info__tags}>
                        Теги: {infoStudentTask.task.theme.map((el, key) => <span key={key}>#{el.title}</span>)}
                    </div>
                </div>
                <div className="col-lg-6 mb-4 d-flex align-items-center justify-content-between">

                    <div className="w-50">
                        <div className={classes.result}>
                            <span>Результат:</span>
                            {placePreloader ? <span className={classes.wait}>Идет проверка ...</span>
                                :
                                <>
                                    {infoStudentTask.status === 1 &&
                                    <span className={classes.complete}>Выполнено!</span>}
                                    {infoStudentTask.status === 2 &&
                                    <span className={classes.notComplete}>Не выполнено!</span>}
                                </>
                            }

                        </div>
                    </div>


                    {
                        nextUrl.url
                            ?

                            <Link
                                to={nextUrl.url}
                                state={{arr: nextUrl.arr, key: nextUrl.key, from: '/taskList'}}
                                className={classes.next}
                            >
                                Следующее задание
                                <div className={classes.icon}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                         fill="currentColor"
                                         className="bi bi-arrow-left-short" viewBox="0 0 16 16">
                                        <path fillRule="evenodd"
                                              d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                                    </svg>
                                </div>
                            </Link>
                            :
                            <Link to="/taskList" className={classes.next}>
                                Вернуться к списку задач
                            </Link>
                    }
                </div>

                {results.info.data === undefined
                    ?
                    ""
                    :
                    results.info.data.length > 0
                    &&
                    <div className="col-lg-6 offset-lg-6">
                        <div className={`${classes.table} w-100`}>
                            <Table striped bordered hover className="mb-0">
                                <thead>
                                <tr>
                                    {
                                        Object.keys(results.info.data[0]).map((el, key) => <th key={key}>{el}</th>)
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    results.info.data.map((elem, key) => {
                                        return (
                                            <tr key={key}>
                                                {Object.keys(results.info.data[0]).map((el, key) => <td
                                                    key={key}>{elem[el]}</td>)}
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                }

                {results.info.message === "Выполнено!"
                    ?
                    ""
                    :
                    <div className="col-lg-6 offset-lg-6">
                        <span className={classes.notCompleteTwo}>{results.info.message}</span>
                    </div>
                }


                <div className="col-lg-6 offset-lg-6 d-flex align-items-center justify-content-end mt-5">
                    <button className={classes.reset} onClick={reset}>сбросить</button>
                    <button className={classes.send} onClick={submitSolution}>отправить</button>
                </div>
            </div>
        </>
    )
}

export default OneExecution;