import React, {useEffect, useState} from "react";
import classes from "./downloadPage.module.css";
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import {useDispatch} from "react-redux";
import {deleteMentorTask, getMentorTasks} from "../../redux/tasksSlice";
import ModalDeleteForTask from "../../components/modalDeleteForTask/ModalDeleteForTask";
import del from "../../images/deleteSvg.svg";
import edit from "../../images/edit.svg";
import ReactPaginate from "react-paginate";
import {onPageChange} from "../../redux/paginationSlice";
import {Form} from "react-bootstrap";
import {getLanguage} from "../../redux/languageGroupSlice";
import {getTags} from "../../redux/tagsSlice";
import {clearDataInput, setDataInput} from "../../redux/dataInputSlice";


const DownloadPage = () => {
    const dispatch = useDispatch();
    const [deleteModal, setDeleteModal] = useState({
        show: false,
        id: null
    });
    const tokenState = useSelector(state => state.authorization.token);
    const mentorTasks = useSelector(state => state.mentorTasks.tasks);
    const pagination = useSelector(state => state.pagination);
    const languages = useSelector(state => state.languages.languages);
    const getTagsList = useSelector(state => state.tags.tags);

    const data = useSelector(state => state.dataInput)

    useEffect(() => {
        if (tokenState.length) {
            const select = pagination.select;
            const {language, tags} = data.data;
            dispatch(getMentorTasks({tokenState, select, language, tags}))
            dispatch(getTags(tokenState));
            dispatch(getLanguage(tokenState));
        }
    }, [tokenState]);


    const deleteTaskBtn = () => {
        let {id} = deleteModal;
        if (tokenState.length) {
            const select = pagination.select;
            const {language, tags} = data.data;
            dispatch(deleteMentorTask({tokenState, id, select, language, tags}));
            setDeleteModal({
                show: !deleteModal.show,
                id: null
            })
        }
    }

    const showDeleteModal = (e) => {
        const id = e.target.dataset.id;
        setDeleteModal({
                show: !deleteModal.show,
                id: +id
            }
        )
    }

    const onChangeSelect = (e) => {
        const {name, value} = e.target
        dispatch(setDataInput({name, value}));
    }

    const onPageChangeFunc = (e) => {
        dispatch(onPageChange(e.selected + 1))
        const select = e.selected + 1
        dispatch(getMentorTasks({tokenState, select}))
    }

    const filterTasks = (e) => {
        e.preventDefault()
        const select = 1;
        const {language, tags} = data.data
        dispatch(getMentorTasks({tokenState, select, language, tags}))
        dispatch(onPageChange(1))
    }

    const resetFilter = (e) => {
        e.preventDefault();
        for (const name in data.data) {
            dispatch(clearDataInput({name}))
        }
        const select = 1;
        const language = undefined;
        const tags = undefined;
        dispatch(getMentorTasks({tokenState, select, language, tags}))
        dispatch(onPageChange(1))
    }

    return (
        <>
            {deleteModal.show ?
                <ModalDeleteForTask showDeleteModal={showDeleteModal} deleteTaskBtn={deleteTaskBtn}/> : ''}
            <div className="container-fluid my-container-padding pt-5 pb-5">

                <div className={`row align-items-center ${classes.downloadPage__wrapper}`}>

                    <div className="col-lg-6">
                        <h2>Загрузка задач</h2>
                    </div>
                    <div className="col-lg-2 offset-lg-4">
                        <NavLink to="/createTasks" className={classes.addTasksLink}>Создать задачу</NavLink>
                    </div>


                </div>

                <div className={classes.tasksBlock}>
                    <div className="row mb-4">
                        <div className="col-3 align-items-center">
                            <Form.Select className="w-100 p-2" style={{border: "none", borderRadius: "8px"}}
                                         name="language" onChange={onChangeSelect} value={data.data.language ? data.data.language : ""}>
                                <option value="">Выберите язык</option>
                                {languages.map((el, key) => <option key={key} value={el.id}>{el.title}</option>)}
                            </Form.Select>
                        </div>
                        <div className="col-3">
                            <Form.Select className="w-100 p-2" style={{border: "none", borderRadius: "8px"}} name="tags"
                                         onChange={onChangeSelect} value={data.data.tags ? data.data.tags : ""}>
                                <option value="">Выберите тэги</option>
                                {getTagsList.map((el, key) => <option key={key} value={el.id}>{el.title}</option>)}
                            </Form.Select>
                        </div>
                        <div className="col-2">
                            <button className={classes.filterBtn} onClick={filterTasks}>
                                Применить фильтр
                            </button>
                        </div>
                        <div className="col-2">
                            <button className={classes.resetBtn} onClick={resetFilter}>
                                Сбросить фильтр
                            </button>
                        </div>

                    </div>


                    {
                        mentorTasks.results.length > 0
                            ?
                            mentorTasks.results.map((item, key) => <ul className={classes.twoColumn} key={key}>
                                    <li>
                                <span className={classes.number}>
                                    {`${(pagination.select - 1 ? pagination.select - 1 : "")}${key + 1}`}
                                </span> {item.title}
                                    </li>
                                    <li>
                                        <NavLink to={`/editTasks/${item.id}`} className={classes.changeBtn}>
                                            <img src={edit} alt={edit} data-id={item.id}/>
                                        </NavLink>
                                        <button className={classes.changeBtn} onClick={showDeleteModal} data-id={item.id}>
                                            <img src={del} alt={del} data-id={item.id}/>
                                        </button>
                                    </li>
                                </ul>
                            )
                            :
                            <div className={classes.groups}>
                                <div className={`${classes.groups__item} w-100`}>
                                    <h2 className="fs-5 fw-normal mb-0">Нет созданых задач</h2>
                                </div>
                            </div>
                    }
                </div>


                {
                    mentorTasks.results.length > 0
                    &&
                    <div className="w-100 d-flex justify-content-end">
                        <ReactPaginate
                            previousLabel={"Назад"}
                            nextLabel={"Следующий"}
                            breakLabel={"..."}
                            pageCount={Math.ceil(mentorTasks.count / 10)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            forcePage={pagination.select - 1}
                            onPageChange={onPageChangeFunc}
                            breakClassName={'page-item'}
                            breakLinkClassName={'page-link'}
                            containerClassName={'pagination'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            activeClassName={'active'}
                        />
                    </div>
                }

            </div>
        </>

    )
}

export default DownloadPage;