import React from "react";
import classes from './modalDeleteForTask.module.css'


const ModalDeleteForTask = ({showDeleteModal, deleteTaskBtn}) => {
    return (
        <div>
            <div className="main_modal">
                <div className="modal_my" onClick={showDeleteModal}>
                </div>
                <div className={classes.modalDelete__wrap}>
                    Вы действительно хотите удалить задачу?
                    <div>
                        <button className={classes.yesBtn} onClick={deleteTaskBtn}>да</button>
                        <button className={classes.noBtn} onClick={showDeleteModal}>нет</button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ModalDeleteForTask;