import React from "react";
import classes from "./stateTasksList.module.css";


const StageTasksList = ({stageList}) => {
    return (
        <div className={classes.stageTasksList__wrapper}>
            <h5 className={classes.title}>Список задач этапа</h5>
            <ul className={classes.list}>
                {stageList.map((el, key) => <li key={key} className="d-flex justify-content-between mb-2">
                    <div className={classes.task}>
                        <span className={classes.number} >{key + 1}</span>
                        <span className="text-center">{el.title}</span>
                        <span className={classes.point}>{el.points} </span>
                    </div>

                </li>)}
            </ul>
        </div>
    )
}

export default StageTasksList;