import {Link} from "react-router-dom";
import taskCss from "./showStudentsTasks.module.css";
import React from "react";



const ShowStudentsTasks = ({taskStudent}) => {
    return (
        <>
            {taskStudent.length > 0 ? taskStudent.map((item, key, arr) => {

                    const status = item.status === 1 ? true : false;
                    return (
                        <Link key={key} to={`/taskExecution/${item.id}`} state={{arr: arr, key: key, from: '/taskList'}}>
                            <li className={taskCss.listPost}>
                                <div className="w-50">{item.task}</div>
                                <div className={`${status ? taskCss.completed : taskCss.notCompleted} w-25`}>
                                            <span
                                                className={status ? taskCss.point : taskCss.pointNot}></span>{status ? 'Выполнено' : 'Не выполнено'}
                                </div>
                                <div className="w-25">
                                    {item.points} баллов
                                </div>
                            </li>
                        </Link>)
                })
                :
                <div className="fs-5">Задач по данному этапу не найдено</div>
            }
        </>
    )
}
export default ShowStudentsTasks;