import React, {useEffect, useState} from 'react';
import mentorCss from "./Mentor.module.css";
import {Link, useLocation} from "react-router-dom";
import ReactPaginate from "react-paginate";
import {useDispatch, useSelector} from "react-redux";
import {getGroups} from "../../redux/mentorGroupSlice";
import Title from "../../components/title/Title";
import {getLanguage} from "../../redux/languageGroupSlice";
import {changeKeyGroup, dateKeyGroup, paginationKey} from "../../redux/getTasksByStageSlice";

import filter from '../../images/filter.svg'

import ModalCalendar from "../../components/modalCalendar/ModalCalendar";
import {createdStageReducer} from "../../redux/StageGroupSlice";


const MentorPage = () => {
    const tokenState = useSelector(state => state.authorization.token);
    const groupsInfo = useSelector(state => state.groupsInfo);
    const languages = useSelector(state => state.languages.languages)
    const lanVal = useSelector(state => state.allTasksByStage.stageGroupKey)
    const dateVal = useSelector(state => state.allTasksByStage.stageDateKey)


    const pagKey = useSelector(state => state.allTasksByStage.pagKey)


    const dispatch = useDispatch();
    const [pageNumber, setPageNumber] = useState(pagKey + 1);
    const [language, setLanguage] = useState(lanVal === 0 ? "" : lanVal);
    const [forcePage, setForcePage] = useState(pagKey)


    let date = new Date();
    let today = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().slice(0, 10);

    const [time, setTime] = useState(dateVal);

    const [modal, setModal] = useState(false);


    let url = useLocation();
    useEffect(() => {
        if (tokenState.length) {
            dispatch(getGroups({tokenState, pageNumber, language, time}));
            dispatch(getLanguage(tokenState));
            dispatch(createdStageReducer([]))
        }
    }, [tokenState, groupsInfo.groupsStatus])


    const onChangePaginate = (e) => {
        const pageNumber = e.selected + 1;
        setForcePage(e.selected)
        setPageNumber(pageNumber);
        dispatch(paginationKey(e.selected))
        dispatch(getGroups({tokenState, pageNumber, language, time}));
    }

    const onChangeTime = (e) => {
        setTime(e.target.value)
        dispatch(dateKeyGroup(e.target.value));
    }

    const showModal = () => {
        setModal(!modal);
    }

    const resetDate = () => {
        const time = ""
        setTime(time);
        dispatch(dateKeyGroup(time));

        const pageNumber = 1;
        setPageNumber(pageNumber);
        dispatch(changeKeyGroup(language))
        setForcePage(0)
        dispatch(getGroups({tokenState, pageNumber, language, time}));
        setModal(!modal)
    }

    const filterByLanguage = (e) => {
        const language = e.target.value;
        const pageNumber = 1;
        setLanguage(language)
        setPageNumber(pageNumber);
        dispatch(changeKeyGroup(language))
        setForcePage(0)
        dispatch(getGroups({tokenState, pageNumber, language, time}));

    }

    const filterByDate = () => {
        const pageNumber = 1;
        setPageNumber(pageNumber);
        dispatch(changeKeyGroup(language))
        dispatch(dateKeyGroup(time));
        setForcePage(0)
        dispatch(getGroups({tokenState, pageNumber, language, time}));
        setModal(!modal)
    }


    return (
        <>

            {modal &&
            <ModalCalendar time={time} onChangeTime={onChangeTime} filterByDate={filterByDate} showModal={showModal}
                           resetDate={resetDate}/>}

            <div className="container-fluid my-container-padding pt-5 pb-5">
                <Title title="Список групп"/>
                <div className={mentorCss.headerFlex}>
                    <div className={mentorCss.table}>
                        <div className={mentorCss.textHeader}>
                            <div className={mentorCss.cars}>
                                <p>Группа</p>
                            </div>
                            <div className={mentorCss.cars}>
                                <select onChange={filterByLanguage} value={lanVal}>
                                    <option value="">Выберите язык</option>
                                    {languages.map((el, key) => <option key={key} value={el.id}>{el.title}</option>)}
                                </select>
                            </div>
                            <div className={mentorCss.cars}>
                                <p>
                                    Дата
                                </p>
                                <button onClick={showModal} className={mentorCss.dateFilter}>
                                    <img src={filter} alt={filter}/>
                                    {time}
                                </button>

                            </div>
                            <div className={mentorCss.cars}>
                                <p>
                                    Действия
                                </p>
                            </div>
                        </div>


                        {groupsInfo.groups.length > 0
                            ?
                            groupsInfo.groups.map((group, key) => {
                                return (
                                    <div className={mentorCss.groups} key={key}>
                                        <div className={mentorCss.groups__item}>
                                            <p>{group.title} </p>
                                        </div>
                                        <div className={mentorCss.groups__item}>
                                            <p>{group.language} </p>
                                        </div>
                                        <div className={mentorCss.groups__item}>
                                            <p>{group.start_date.slice(0, 10)}</p>
                                        </div>
                                        <div className={mentorCss.groups__item}>
                                            <Link to={`${url.pathname}/${group.id}`} className={mentorCss.changeBtn}>
                                                Редактировать
                                            </Link>
                                            <Link to={`${url.pathname}/logPage/${group.id}`}
                                                  className={mentorCss.changeBtn}>
                                                Журнал
                                            </Link>
                                        </div>

                                    </div>
                                )
                            })
                            :
                            <div className={mentorCss.groups}>
                                <div className={`${mentorCss.groups__item} w-100`}>
                                    <h2 className="fs-5 fw-normal mb-0">По данному языку групп не найдено </h2>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                {groupsInfo.groups.length > 0
                &&
                <div className="w-100 d-flex justify-content-end">
                    <ReactPaginate
                        previousLabel={"Назад"}
                        nextLabel={"Следующий"}
                        breakLabel={"..."}
                        pageCount={Math.round(groupsInfo.paginationCount / 2 + 0.1)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        forcePage={forcePage}
                        onPageChange={onChangePaginate}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        containerClassName={'pagination'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        activeClassName={'active'}
                    />
                </div>

                }


            </div>
        </>
    )
}


export default MentorPage;
