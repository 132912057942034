import {createSlice} from "@reduxjs/toolkit";


const paginationSlice = createSlice({
        name: "paginationSlice",
        initialState: {
            pageCount: 0,
            select: 1
        },
        reducers: {
            onPageChange(state, action) {
                state.select = action.payload
            }
        }
    })


export const {onPageChange} = paginationSlice.actions;

export default paginationSlice.reducer