import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {API, BASE_URL_SECOND} from "../api/api";
import {toast} from "react-toastify";
import {clearAllData, clearDataInput, setDataInput} from "./dataInputSlice";

export const getQuestions = createAsyncThunk(
    'getQuestions',
    async function (data, {rejectWithValue}) {
        try {
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                }
            }
            const response = await fetch(`${BASE_URL_SECOND}${API.mentor.get_questions}?subjectId=${data.id}`, options)
            if (response.status === 200) {
                const data = await response.json();
                return data
            } else if (response.status >= 400 || response.status <= 407) {
                const data = await response.json();
                throw Error(data.message)
            }
        } catch (e) {
            return rejectWithValue(e.message)
        }
    }
)

export const createImgQuestion = createAsyncThunk(
    'createImgQuestion',
    async function (data, {rejectWithValue, dispatch}) {
        try {
            let options = {
                method: "POST",
                headers: {
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                },
                body: data.formData
            }
            const response = await fetch(`${BASE_URL_SECOND}${API.mentor.add_img_to_question}?questionId=${data.idQuestion}`, options);

            if (response.status === 200) {
                const {id, tokenState} = data
                dispatch(getQuestions({tokenState, id}))
                toast.success("Вопрос с фото успешно создан");
                dispatch(clearDataInput({name: "question"}))
                dispatch(clearDataInput({name: "file"}))
            }
            else {
                toast.error("Ошибка при редактировании")
                throw Error("Ошибка при редактировании")
            }
        } catch (e) {
            return rejectWithValue(e.message)
        }
    }
)

export const createQuestionPost = createAsyncThunk(
    'createQuestionPost',
    async function (data, {rejectWithValue, dispatch}) {
        try {
            let options = {
                method: data.type === "create" ? "POST" : "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                },
                body: JSON.stringify(data.questionInfo)
            }
            const response = await fetch(
                data.type === "create" ? `${BASE_URL_SECOND}${API.mentor.save_question}`
                    : `${BASE_URL_SECOND}${API.mentor.update_question}`,
                options);


            if (response.status === 200) {
                const Data = await response.json()
                if (data.formData) {
                    const idQuestion = Data.id
                    const {tokenState, formData} = data
                    const id = data.questionInfo.subjectId
                    dispatch(createImgQuestion({tokenState, formData, idQuestion, id}))
                } else {
                    const {tokenState} = data
                    const id = data.questionInfo.subjectId
                    dispatch(getQuestions({tokenState, id}))
                    dispatch(clearDataInput({name: "question"}))
                    dispatch(updateQuestionOff());
                    data.type === "create" ? toast.success("Вопрос успешно создан") : toast.success("Вопрос успешно отредактирован")
                }

            } else if (response.status === 406) {
                const Data = await response.json()
                toast.error(Data.message)
                throw Error(Data.message)

            }
        } catch (e) {
            return rejectWithValue(e.message)
        }
    }
)


export const getOneQuestion = createAsyncThunk(
    'getOneQuestion',
    async function (data, {rejectWithValue, dispatch}) {
        try {
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                }
            }

            const response = await fetch(`${BASE_URL_SECOND}${API.mentor.get_question_by_id}?id=${data.id}`, options)

            if (response.status === 200) {
                const data = await response.json();
                for (let key in data) {
                    if (key === "question") {
                        dispatch(setDataInput({name: key, value: data[key]}))
                        dispatch(updateQuestionOn())
                    }

                }


                return data;
            }
        } catch (e) {
            return rejectWithValue(e.message)
        }


    }
)


const questionsSlice = createSlice({
        name: 'questionsSlice',
        initialState: {
            questions: [],
            error: null,
            answers: [],
            update: false,
            image: 'string'
        },
        reducers: {
            addAnswer(state, action) {
                state.answers = [
                    ...state.answers, {
                        answer: action.payload,
                        true: false,
                    }
                ]
            },
            clearAnswers(state) {
                state.answers = []
            },
            changeAnswerStatus(state, action) {
                state.answers[action.payload].true = !state.answers[action.payload].true
            },
            changeAnswerTitle(state, action) {
                state.answers[action.payload.key].answer = action.payload.title
            },
            deleteAnswerCase(state, action) {
                state.answers.splice(+action.payload, 1)
            },
            updateQuestionOn(state) {
                state.update = true
            },
            updateQuestionOff(state) {
                state.update = false
            },
            deletePicture (state) {
                state.image = 'string'
            }
        },
        extraReducers: builder => {
            builder.addCase(getQuestions.fulfilled, (state, action) => {
                state.questions = action.payload;
                state.answers = []
            })
            builder.addCase(getQuestions.rejected, (state, action) => {
                state.error = action.payload
                state.questions = []
            })

            builder.addCase(createQuestionPost.fulfilled, (state, action) => {
                state.image = ""

            })

            builder.addCase(createQuestionPost.rejected, (state, action) => {
                state.error = action.payload;

            })

            builder.addCase(createImgQuestion.fulfilled, (state, action) => {
                state.answers = []
                state.image = ""
            })

            builder.addCase(getOneQuestion.fulfilled, (state, action) => {
                state.answers = [...action.payload.answers]
                state.image = action.payload.image
            })
        }
    }
)

export const {
    addAnswer,
    deleteAnswerCase,
    changeAnswerStatus,
    changeAnswerTitle,
    clearAnswers,
    updateQuestionOn,
    updateQuestionOff,
    deletePicture
} = questionsSlice.actions;
export default questionsSlice.reducer;
