import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import MenuLog from "../../components/menuLog/MenuLog";
import Log from "../../components/log/Log";
import {useParams} from "react-router-dom";
import {getStage} from "../../redux/StageGroupSlice";
import {changePassCount, getReportBySearch, getReportByStage} from "../../redux/reportSlice";
import Preloader from "../../components/preloader/Preloader";
import LogVisits from "../../components/logVisits/LogVisits";
import {
    changeVisits,
    createDateThunk,
    deleteDate,
    getStudentsVisits,
    setDateDelete,
    updateDate
} from "../../redux/logVisitsSlice";
import {setDataInput} from "../../redux/dataInputSlice";
import {toast} from "react-toastify";
import classes from "./logPage.module.css"
import ModalDelete from "../../components/modalDelete/ModalDelete";
import {showCalendarModal, showDelModal} from "../../redux/deleteModalSlice";
import ModalCalendar from "../../components/modalCalendar/ModalCalendar";


const LogPage = () => {
    const tokenState = useSelector(state => state.authorization.token);
    const stagesGroup = useSelector(state => state.stagesGroup.groupStage);

    const testId = useSelector(state => state.stagesGroup.id);
    const reports = useSelector(state => state.reports.report);
    const preloader = useSelector(state => state.preloader.preloader);

    const deleteModalStatus = useSelector(state => state.delModal.status);
    const calendarStatus = useSelector(state => state.delModal.calendarStatus);


    const date = useSelector(state => state.dataInput.data)

    const studentVisits = useSelector(state => state.studentsVisits.visits);
    const dateForUpdate = useSelector(state => state.studentsVisits.date);

    const dateFromModal = useSelector(state => state.dataInput.data)

    const dispatch = useDispatch();
    const {id} = useParams();

    const [stageId, setStageId] = useState(0)
    const [active, setActive] = useState(0)
    const [navActive, setNavActive] = useState(true);
    const [searchValue, setSearchValue] = useState('')


    useEffect(() => {
        if (tokenState.length) {
            dispatch(getStage({tokenState, id}));
            dispatch(getStudentsVisits({tokenState, id}))
        }
    }, [tokenState]);


    useEffect(() => {
        const id = testId;
        setStageId(id)
        setActive(id)
        dispatch(getReportByStage({tokenState, id}));

    }, [testId])


    const btnForStage = (e) => {
        e.preventDefault();
        let id = +e.target.dataset.id;
        setActive(id)
        dispatch(getReportByStage({tokenState, id}));
        setStageId(+id);
    }
    const onChangeSearch = (e) => {
        e.preventDefault();
        const name = e.target.value;
        setSearchValue(e.target.value);
        dispatch(getReportBySearch({tokenState, stageId, name}));
    }

    const onChangeInput = (e) => {
        const {id} = e.target.dataset;
        const pass_count = +e.target.value;
        dispatch(changePassCount({tokenState, pass_count, id, stageId, searchValue}))
    }

    const onChangeDate = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        dispatch(setDataInput({name, value}))
    }

    const createDate = () => {
        const data = {
            group: +id,
            date: date.date
        }

        const name = "date";
        const value = "";

        if (data.date === undefined) {
            toast.error("Дата пустая");
            return
        }
        dispatch(createDateThunk({tokenState, data}))
        dispatch(dispatch(setDataInput({name, value})))
    }

    const changePresense = (e) => {
        const info = {
            id: +e.target.dataset.id,
            group: +id
        }

        if (e.target.value === "+") {
            const data = {
                presence: true,
            }
            dispatch(changeVisits({tokenState, data, info}))
        } else if (e.target.value === "-") {
            const data = {
                presence: false,
            }
            dispatch(changeVisits({tokenState, data, info}))
        }
    }


    const changeComponent = (e) => {
        e.preventDefault();


        setNavActive(!navActive);
    }

    const delDateModal = (e) => {
        dispatch(showDelModal())
        dispatch(setDateDelete(e.target.dataset.date))
    }

    const updateDateModal = (e) => {
        const name = "date_modal";
        const value = "";
        dispatch(setDataInput({name, value}))
        dispatch(showCalendarModal())
        dispatch(setDateDelete(e.target.dataset.date))
    }

    const deleteDateFunc = () => {
        const data = {
            group: +id,
            date: dateForUpdate
        }
        dispatch(deleteDate({tokenState, data}))
    }

    const updateDateFunc  = () => {
        const data  = {
            group: +id,
            date: dateForUpdate,
            new_date: dateFromModal.date_modal
        }
        dispatch(updateDate({tokenState, data}))
    }




    return (
        <>

            {calendarStatus && <ModalCalendar
                showModal={updateDateModal}
                resetDate={updateDateModal}
                onChangeTime={onChangeDate}
                filterByDate={updateDateFunc}
                time={dateFromModal.date_modal}
            />}


            {deleteModalStatus &&
            <ModalDelete
                showDeleteModal={delDateModal}
                deleteModal="test"
                deleteStageBtn={deleteDateFunc}/>}

            <div className="my-container-padding pt-5 pb-5">
                {preloader
                    ?
                    <Preloader/>
                    :
                    <>
                        <div className={`${classes.navigations} mb-5`}>
                            <button onClick={changeComponent} className={navActive ? "active" : ""}>Журнал оценок
                            </button>
                            <button onClick={changeComponent} className={navActive ? "" : "active"}>Журнал посещений
                            </button>
                        </div>


                        {
                            navActive
                                ?

                                <div>
                                    <MenuLog stagesGroup={stagesGroup} btnForStage={btnForStage} active={active}/>
                                    <Log reports={reports} onChangeInput={onChangeInput}
                                         onChangeSearch={onChangeSearch}/>
                                </div>

                                :
                                <LogVisits studentVisits={studentVisits} onChangeDate={onChangeDate}
                                           createDate={createDate}
                                           changePresense={changePresense} delDateModal={delDateModal}
                                           updateDateModal={updateDateModal}
                                           dateFromModal={dateFromModal}
                                />

                        }

                    </>
                }
            </div>

        </>

    )

}

export default LogPage;