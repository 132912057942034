import React from "react";
import {useNavigate} from "react-router-dom";
import classes from './goBack.module.css';


const GoBack = () => {
    const navigate = useNavigate();
    return (
        <div className={`${classes.btn__wrap}`}>
                <button onClick={() => navigate(-1)} className={classes.exitBtn}>
                    <div className={classes.icon_wrap}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                             className="bi bi-arrow-left-short" viewBox="0 0 16 16">
                            <path fillRule="evenodd"
                                  d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                        </svg>
                    </div>
                    <div className={classes.menuName}>Назад</div>
                </button>
        </div>
    )
}

export default GoBack;