import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {API, BASE_URL} from "../api/api";
import {toast} from "react-toastify";
import {hidePreloader, showPreloader} from "./preloaderSlice";



export const createStagePost = createAsyncThunk(
    'createStagePost',
    async (data, {rejectWithValue, dispatch}) => {
        try {
            let options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                },
                body: JSON.stringify(data.stageData)
            }
            const response = await fetch(`${BASE_URL}${API.mentor.createStage}`, options);
            if (response.ok) {
                dispatch(getStage(data))
                toast.success("Этап успешно создан")
            } else {
                const data = await response.json();
                toast.error(data[0])
            }


        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)


export const getOneStage = createAsyncThunk(
    'getOneStage',
    async (data, {rejectWithValue, dispatch}) => {
        try {
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                }
            }
            const response = await fetch(`${BASE_URL}${API.mentor.StageGroup}${data.id}`, options);
            if (response.ok) {
                const data = await response.json()
                dispatch(getOneStageReducer({data}))
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const updateOneStage = createAsyncThunk(
    'updateOneStage',
    async (data, {rejectWithValue, dispatch}) => {

        try {
            let options = {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                },
                body: JSON.stringify(data.stageOneInfo)
            }
            const response = await fetch(`${BASE_URL}${API.mentor.StageGroup}${data.stageOneInfo.id}`, options);

            if (response.ok) {
                const data = await response.json()
                dispatch(getOneStage(data))
                toast.success("Данные успешно обновлены")
            }else {
                const data = await response.json()
                toast.error(data[0])
                return
            }
            dispatch(getStage(data));


        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)



export const getStage = createAsyncThunk(
    'getStage',
    async (data, {rejectWithValue, dispatch}) => {

        try {
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                }
            }

            dispatch(showPreloader())

            const response = await fetch(`${BASE_URL}${API.mentor.getGroupStage}?group_id=${data.id}`, options);
            if (response.ok) {
                const info = await response.json()

                dispatch(getStageReducer({info}));
                dispatch(hidePreloader())
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }

    }
)

export const deleteStage = createAsyncThunk(
    'deleteStage',
    async (data, {rejectWithValue, dispatch}) => {
        try {
            let options = {
                method: 'DELETE',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                }
            }
            const response = await fetch(`${BASE_URL}${API.mentor.StageGroup}${data.idStage}`, options);
            if (response.ok) {
                dispatch(getStage(data))
                toast.success("Этап успешно удален")
            } else {
                const data = await response.json();
                toast.error(data[0])
            }

        } catch (error) {
            return rejectWithValue(error.message)
        }


    }
)


const stageSlice = createSlice({
    name: 'stage',
    initialState: {
        groupStage: [],
        oneStage: {},
        id: 0,
        createdStage: []
    },
    reducers: {
        getStageReducer(state, action) {

            if(action.payload.info.length > 0) {
                state.groupStage = action.payload.info
                state.id = action.payload.info[0].stage.id
            }

            else {
                state.groupStage = []
            }

        },
        getOneStageReducer(state, action) {
            state.oneStage = action.payload.data
        },
        createdStageReducer(state,action) {
            state.createdStage = action.payload
        }
    }
})


export const {getStageReducer, getOneStageReducer, createdStageReducer} = stageSlice.actions

export default stageSlice.reducer