import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import classes from "./goBackStudent.module.css";




const GoBackStudent = () => {
    const navigate = useNavigate();
    const url = useLocation();
    return (
        <div className="container-fluid my-container-student">
            <div className={classes.wrapper}>
                <button onClick={() => navigate(url.state.from)} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                         className="bi bi-arrow-left-short" viewBox="0 0 16 16">
                        <path fillRule="evenodd"
                              d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                    </svg>
                </button>
            </div>

        </div>

    )
}

export default GoBackStudent;