import React from "react";
import classes from './modalDelete.module.css'


const ModalDelete = ({showDeleteModal, deleteModal, deleteStageBtn}) => {
    return (
        <div>
            <div className="main_modal">
                <div className="modal_my" onClick={showDeleteModal}>
                </div>
                <div className={classes.modalDelete__wrap}>
                        Вы действительно хотите удалить: <strong>{deleteModal.title}</strong> ?
                    <div>
                        <button className={classes.yesBtn} onClick={deleteStageBtn} data-id={deleteModal.id}>да</button>
                        <button className={classes.noBtn} onClick={showDeleteModal}>нет</button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ModalDelete;