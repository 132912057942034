import React, {useEffect, useState} from 'react';
import taskCss from "./TaskList.module.css";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import ShowStudentsTasks from "../../components/showStudentsTasks/ShowStudentsTasks";
import {getAllStage} from "../../redux/getAllStudentStageSlice";
import {changeKey, getStageById} from "../../redux/getTasksByStageSlice";
import Preloader from "../../components/preloader/Preloader";


const TaskList = () => {
    const tokenState = useSelector(state => state.authorization.token2);

    const taskStudent = useSelector(state => state.allTasksByStage.singleStageTasks);
    const stageNum = useSelector(state => state.allTasksByStage.stageKey)

    const stage = useSelector(state => state.allStage.allStageList);
    const preloader = useSelector(state => state.preloader.preloader)
    const singleStage = useSelector(state => state.allTasksByStage.singleStageTasks);
    const [showTaskState, setShowTaskState] = useState(null);


    const dispatch = useDispatch();

    useEffect(() => {
        if (tokenState.length) {
            dispatch(getAllStage(tokenState));
            if (stage.length > 0) {
                const id = stage[stageNum].stage;
                setShowTaskState(stage[stageNum].stage);
                dispatch(getStageById({tokenState, id}))
            }
        }
    }, [tokenState, stage.length])

    const handleShowTasks = (e) => {
        e.preventDefault();
        const target = e.target
        const {id, key} = target.dataset;
        changeBackground(id);
        dispatch(getStageById({tokenState, id}));
        dispatch(changeKey(key))
    }
    const changeBackground = id => {
        setShowTaskState(id);
    }

    return (
        <div className="container-fluid my-container-student pb-5">

            <div className={taskCss.taskList}>
                <div className={taskCss.flexList}>
                    <div className={taskCss.stages}>
                        {
                            stage.map((item, key) => {
                                return (
                                    <Link key={key} data-id={item.stage} data-key={key} to="" onClick={handleShowTasks}>
                                        <div data-id={item.stage} data-key={key}
                                             className={showTaskState == item.stage ? taskCss.taskTitleActive : taskCss.taskTitle}>{item.name_stage} </div>
                                    </Link>
                                )
                            })
                        }
                    </div>

                    <ul className={taskCss.taskLink}>

                        {preloader
                            ?
                            <Preloader/>
                            :
                            <ShowStudentsTasks taskStudent={taskStudent} singleStage={singleStage}/>
                        }

                    </ul>

                    {stage.map((item, key) =>
                        <div className={showTaskState != item.stage ? taskCss.hideMark : taskCss.ShowMark}
                             key={key}>
                            <span>Проходной балл</span>
                            <span>{item.pass_grade}</span>
                        </div>)}

                </div>
            </div>

        </div>
    );
};

export default TaskList;





