import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {API, BASE_URL, BASE_URL_SECOND} from "../api/api";




export const getTagsTests = createAsyncThunk(
    'getTagsTests',
    async (data, {rejectWithValue, dispatch}) => {
        try {
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                }
            }
            const response = await fetch(`${BASE_URL_SECOND}${API.mentor.tagsForTest}?langId=${data.id}`, options);
            if (response.status === 200) {
                const data = await response.json();
                dispatch(getTagsForTest({data}))
            }
            else {
                const data = [];
                dispatch(getTagsForTest({data}))
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)



export const getTags = createAsyncThunk(
    'getTags',
    async (token, {rejectWithValue, dispatch}) => {

        try {
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${token[0].auth_token}`
                }
            }
            const response = await fetch(`${BASE_URL}${API.mentor.getTag}`, options);
            if (response.ok) {
                const data = await response.json();
                dispatch(getTagsReducer({data}))
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)



const getTagsSlice = createSlice({
        name: 'getTags',
        initialState: {
            tags: [],
            tagsTests: []
        },
        reducers: {
            getTagsReducer(state, action) {
                state.tags = action.payload.data
            },
            getTagsForTest(state,action) {
                state.tagsTests = action.payload.data
            }
        }
    }
)

export const {getTagsReducer, getTagsForTest} = getTagsSlice.actions

export default getTagsSlice.reducer