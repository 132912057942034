import {createSlice} from "@reduxjs/toolkit";



const dataInputSlice = createSlice({
    name: "dataInput",
    initialState: {
        data: {}
    },
    reducers: {
        setDataInput(state, action) {
            state.data[action.payload.name] = action.payload.value
        },
        clearDataInput: (state, action) => {
            state.data[action.payload.name] = ""
        },
        clearAllData: (state, action) => {
            state.data = {}
        }
    }
})

export const {setDataInput, clearDataInput, clearAllData} = dataInputSlice.actions

export default dataInputSlice.reducer