import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {API, BASE_URL,BASE_URL_SECOND} from "../api/api";
import {toast} from "react-toastify";

export const createTheme = createAsyncThunk(
    'createTheme',
    async (data, {rejectWithValue, dispatch}) => {

        try {
            let options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                },
                body: JSON.stringify(data.data)
            }
            const response = await fetch(`${BASE_URL}${API.mentor.themes}`, options);

            if (response.ok) {
                toast.success("Тема успешно создана");
                dispatch(clearValueThemeReducer());
            } else {
                toast.error(data[0])
            }

        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const getThemes = createAsyncThunk(
    'getThemes',
    async (data, {rejectWithValue, dispatch}) => {

        try {
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                },
            }
            const response = await fetch(`${BASE_URL_SECOND}${API.mentor.themesList}?langId=${data.id}`, options);
            if (response.ok) {
                const data = await response.json();

                const themes = [...data]

                dispatch(getThemesReducer({themes}));

            } else {
                const data = await response.json();
                toast.error(`${data.message}`)
                const themes = []
                dispatch(getThemesReducer({themes}));
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

const themesInfoSlice = createSlice({
    name: 'themesInfo',
    initialState: {
        themes: [],
        oneTheme:{ title: '',
            description: '',
            language: 0
        }
    },
    reducers: {
        getThemesReducer(state, action) {
            state.themes = action.payload.themes
        },
        clearValueThemeReducer(state){
            state.oneTheme={
                title: '',
                description: '',
                language: 0
            }
        }

    }
})

export const {getThemesReducer,clearValueThemeReducer} = themesInfoSlice.actions;

export default themesInfoSlice.reducer;