import React from "react";
import classes from "./modalCalendar.module.css";
import {Form} from "react-bootstrap";


function ModalCalendar({time, onChangeTime, filterByDate, showModal, resetDate}) {
    return (
        <div>
            <div className="main_modal">
                <div className="modal_my" onClick={showModal}>
                </div>
                <div className={classes.modal__wrap}>
                    Выберите дату
                    <div className="mt-3">

                        <Form>
                            <Form.Control type="date" value={time} onChange={onChangeTime} name="date_modal"/>
                        </Form>

                    </div>
                    <div>
                        <button className={classes.yesBtn} onClick={filterByDate}>Применить</button>
                        <button className={classes.noBtn} onClick={resetDate}>Сбросить</button>
                    </div>

                </div>


            </div>
        </div>
    )
}


export default ModalCalendar;