import React from "react";
import {Outlet} from "react-router-dom";
import GoBackStudent from "../goBackStudent/GoBackStudent";



const StudentLayoutBack = () => {
    return (
        <>
            <GoBackStudent/>
            <Outlet/>
        </>

    )
}

export default StudentLayoutBack;