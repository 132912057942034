import React from 'react';
import classes from "../test/test.module.css";
import {Link} from "react-router-dom";
import {Form} from "react-bootstrap";

const Themes = ({getTagsList, url, languagesStore, changeTags, data}) => {
    return (
        <div>
            <div className="row">
                <div className="col-lg-4">
                    <Form.Select className={classes.select}
                                 name="languageTheme" onChange={changeTags} value={data.languageTheme ? data.languageTheme : ""}>
                        <option value="">Выберите язык</option>
                        {languagesStore.map(el => <option key={el.id} value={el.id}>{el.title}</option>)}
                    </Form.Select>
                </div>
            </div>
            <div className={classes.testWrapper}>
                <div className="row">
                    {getTagsList.length > 0
                        ?
                        getTagsList.map((theme, key) => {
                            return (
                                <div className={`${classes.testInfo} col-lg-4`} key={key}>
                                    <div className={classes.test}>
                                        <div className={classes.description}>
                                            <div>
                                                <span>
                                                    {theme.title}
                                                </span>
                                                <p>
                                                    Количество вопросов: {theme.questionsAmount}
                                                </p>
                                            </div>
                                            <div className={classes.btns}>
                                                <Link to={`${url.pathname}/${theme.id}`}
                                                      className={classes.addQuestion}>
                                                    Добавить вопрос
                                                </Link>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            )
                        })
                        :
                        <div className="row">
                            <h2 className="fs-5 fw-normal">Тем нет</h2>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
};

export default Themes;