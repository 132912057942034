import React from "react";
import classes from './settingPage.module.css';
import {NavLink} from "react-router-dom";


const SettingPage = () => {
    return (
        <div className="container-fluid my-container-padding pt-5">
            <div className={classes.wrapper}>
                <h2>Создание языка</h2>

                <NavLink to="/createThemePage" className={classes.link}>
                    <div className={classes.icon_wrap}>

                    </div>
                    <div className={classes.menuName}>+ Создать тему</div>
                </NavLink>


                <NavLink to="/createLanguage" className={classes.link}>
                    <div className={classes.icon_wrap}>

                    </div>
                    <div className={classes.menuName}> + Создать язык</div>
                </NavLink>

                <NavLink to="/createSqlTable" className={classes.link}>
                    <div className={classes.icon_wrap}>

                    </div>
                    <div className={classes.menuName}> + Создать таблицу sql</div>
                </NavLink>

            </div>
        </div>
    )
}

export default SettingPage;