import classes from './test.module.css';
import {Link} from "react-router-dom";
import deleteIcon from '../../images/deleteSvg.svg'
import edit from '../../images/edit.svg'
import React from "react";
import {Form} from "react-bootstrap";


const Test = ({tests, showDeleteModal, testsOfGroup, changeTest, testNum}) => {

    return (

        <div>

            <div className="row">
                <div className="col-lg-4">
                    <Form.Select onChange={changeTest} className={classes.select} value={testNum}>
                        {testsOfGroup.map((option, key) => <option value={key} key={key}>{option.title}</option>)}
                    </Form.Select>
                </div>
            </div>

            <div className={classes.testWrapper}>

                {tests.length > 0
                    ?
                    <div className="row">
                        {tests.map((test, key) => {
                            return (
                                <div className={`${classes.testInfo} col-lg-4`} key={key}>
                                    <div className={classes.test} >
                                        <div className={classes.description}>
                                            <div>
                                                <span>{test.test_name}</span>
                                                <p>
                                                    {test.count_question} вопросов
                                                </p>
                                            </div>
                                            <div className={classes.btns}>
                                                <Link to={""} className={classes.btn}>
                                                    <img src={edit} alt={edit}/>
                                                </Link>
                                                <button className={classes.btn} onClick={showDeleteModal} data-id={test.id} data-title={test.test_name}>
                                                    <img src={deleteIcon} alt={deleteIcon} data-id={test.id} data-title={test.test_name}/>
                                                </button>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    :
                    <div className="row">
                        <h2 className="fs-5 fw-normal">В данной группе тестов не существуют</h2>
                    </div>
                }

            </div>


        </div>












    )
}


export default Test;