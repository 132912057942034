import React from "react";
import classes from "./createStage.module.css";
import download from '../../images/download.svg'




const CreateStage = ({modalState, onChangeInputCreate, createStageBtn, stageData, today, points}) => {

    return (
        <div className={classes.createStage__wrapper}>
            <h5 className={classes.title}>
                 Создание этапа
            </h5>
            <div className={classes.createStage__inputs}>
                <div className={classes.createStage__inputs_item}>
                    <span>Название этапа:</span>
                    <input type="text" placeholder="Название этапа" name="title" onChange={onChangeInputCreate} value={stageData.title}/>
                </div>

                <div className={classes.createStage__inputs_item}>
                    <span>Кол-во дней:</span>
                    <input type="number" placeholder="Кол-во дней" name="duration" onChange={onChangeInputCreate} value={stageData.duration} min="1"/>
                </div>

                <div className={classes.createStage__inputs_item}>
                    <span>Начало этапа:</span>
                    <input type="date"  name="date_after" onChange={onChangeInputCreate} value={stageData.date_after} min={today}/>
                </div>


                <div className={classes.createStage__inputs_item}>
                    <span>Оценка за этап:</span>
                    <input type="number"   name="pass_grade" placeholder="100" disabled  value={points} min="0"/>
                </div>


                <div className={classes.createStage__inputs_item}>
                    <span>Порядок этапа:</span>
                    <input type="number"  name="order_num" placeholder="1" onChange={onChangeInputCreate} value={stageData.order_num} min="1"/>
                </div>

                <div className={classes.createStage__inputs_item}>
                    <span>Статус этапа:</span>
                    <select onChange={onChangeInputCreate} value={stageData.condition} name="condition">
                        <option value="">выбрать</option>
                        <option value="1">активный</option>
                        <option value="2">закрытый</option>
                        <option value="3">ждет очереди</option>
                    </select>
                </div>

                <div className={classes.createStage__inputs_item}>
                    <span>Загрузка:</span>
                    <button onClick={modalState}><img src={download} alt={download}/>Загрузка задач</button>
                </div>
                <button  className={classes.submitBtn} onClick={createStageBtn}>Создать +</button>
            </div>
        </div>
    )
}

export default CreateStage;