import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {API, BASE_URL} from "../api/api";
import {toast} from "react-toastify";
import {hidePreloader, showPreloader} from "./preloaderSlice";


export const getStudentTaskById = createAsyncThunk(
    'getStudentTaskById',
    async (data, {rejectWithValue, dispatch}) => {
        try {
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                }
            }

            dispatch(showPreloader())
            const response = await fetch(`${BASE_URL}${API.mentor.studentTask}${data.id}/`, options);
            if (response.ok) {
                const data = await response.json();
                dispatch(oneTaskInfoReducer({data}))

                dispatch(hidePreloader())
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)


export const getMentorTasksStage = createAsyncThunk(
    'getMentorTasksStage',
    async (data, {rejectWithValue, dispatch}) => {
        try {
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                }
            }

            const response = await fetch(`${BASE_URL}${API.mentor.task_by_theme}?language=${data.language}`, options);
            if (response.ok) {
                const data = await response.json();
                dispatch(themes(data))
                // console.log(data)
            }


        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const getMentorTasks = createAsyncThunk(
    'getMentorTasks',
    async (data, {rejectWithValue, dispatch}) => {
        try {
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                }
            }

            const params = {
                select: data.select ? data.select : "",
                language: data.language ? data.language : "",
                tags: data.tags ? data.tags : "",
            }

            const response = await fetch(`${BASE_URL}${API.mentor.getMentorTasks}?page=${params.select}&language=${params.language}&theme=${params.tags}`, options);
            if (response.ok) {
                const data = await response.json();
                dispatch(getMentorTasksReducer({data}))
            }


        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)
export const getMentorTaskById = createAsyncThunk(
    'getMentorTaskById',
    async (data, {rejectWithValue, dispatch}) => {
        try {
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                }
            }
            const response = await fetch(`${BASE_URL}${API.mentor.getMentorTasks}${data.id}/`, options);
            if (response.ok) {
                const data = await response.json();
                dispatch(getMentorTaskByIdReducer({data}))
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const saveTaskById = createAsyncThunk(
    'saveTaskById',
    async (data, {rejectWithValue, dispatch}) => {
        console.log(data.data.id);
        try {
            let options = {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                },
                body: JSON.stringify(data.data)
            }
            const response = await fetch(`${BASE_URL}${API.mentor.getMentorTasks}${data.data.id}/`, options);
            if (response.ok) {
                const data = await response.json();

                dispatch(getMentorTaskByIdReducer({data}))

                toast.success("Успешно изменено")
            }
        } catch (error) {
            toast.error("Что-то пошло не так!")
            return rejectWithValue(error.message)
        }
    }
)

export const createMentorTasks = createAsyncThunk(
    'createMentorTasks',
    async (data, {rejectWithValue, dispatch}) => {

        try {
            let options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                },
                body: JSON.stringify(data.data)
            }

            const response = await fetch(`${BASE_URL}${API.mentor.getMentorTasks}`, options);
            if (response.ok) {
                const data = await response.json();
                toast.success('Успешно создано!');

                dispatch(clearValueReducer())
            } else {
                const data = await response.json();
                toast.error(data[0])
            }

        } catch (error) {
            return rejectWithValue(error.message)
        }


    }
)
export const deleteMentorTask = createAsyncThunk(
    'deleteMentorTask',
    async (data, {rejectWithValue, dispatch}) => {
        try {
            let options = {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                }
            }
            const response = await fetch(`${BASE_URL}${API.mentor.getMentorTasks}${data.id}/`, options);
            if (response.ok) {
                const {tokenState, select, language, tags} = data
                dispatch(getMentorTasks({tokenState, select, language, tags}))
                toast.success('Успешно удалено!');
            } else {
                const data = await response.json();
                toast.error(data[0])
            }

        } catch (error) {
            return rejectWithValue(error.message)
        }


    }
)


const mentorTasksSlice = createSlice({
        name: 'mentorTasks',
        initialState: {
            tasks: {
                results: []
            },
            tasksStage: [],
            singleTask: {},
            sendData: {},
            oneTaskInfo: {
                task: {
                    title: '',
                    order_num: '',
                    description: '',
                    project: false,
                    language: '',
                    sample_text: '',
                    test_cases: '',
                    points: '',
                    theme: []
                }
            }
        },
        reducers: {
            getMentorTasksReducer(state, action) {
                state.tasks = action.payload.data
            },
            getMentorTaskByIdReducer(state, action) {
                state.singleTask = action.payload.data
            },
            saveTaskByIdReducer(state, action) {
                state.sendData = action.payload.data
            },
            oneTaskInfoReducer(state, action) {
                state.oneTaskInfo = action.payload.data
            },
            clearValueReducer(state) {
                state.oneTaskInfo = {
                    task: {
                        title: '',
                        order_num: '',
                        description: '',
                        project: false,
                        language: '',
                        sample_text: '',
                        test_cases: '',
                        points: '',
                        theme: []
                    }
                }
            },

            themes(state,action) {
                state.tasksStage = action.payload
            },
            themesNone(state) {
                state.tasksStage = []
            }
        }
    }
)

export const {
    getMentorTasksReducer,
    getMentorTaskByIdReducer,
    saveTaskByIdReducer,
    oneTaskInfoReducer,
    clearValueReducer,
    themes,
    themesNone
} = mentorTasksSlice.actions

export default mentorTasksSlice.reducer
