import React from 'react';
import {Routes, Route} from "react-router-dom";
import Login from "../pages/login/Login";
import TaskList from "../pages/taskList/TaskList";

import Change from "../pages/mentor/change/Change";
import ForgotPass from "../pages/login/ForgotPass/ForgotPass";
import NotPageFound from "../pages/notPageFound";
import MentorPage from "../pages/mentorPage/MentorPage";
import SettingsGroup from "../components/settingsGroup/SettingsGroup";
import Layout from "../components/Layout/Layout";
import LayoutBack from "../components/Layout/LayoutBack";
import DownloadPage from "../pages/downloadPage/DownloadPage";
import CreateTasks from "../components/createTasks/createTasks";
import LogPage from "../pages/logPage/LogPage";
import TestsPage from "../pages/testsPage/TestsPage";
import CreateTest from "../components/createTest/CreateTest";
import CreateThemePage from "../components/createThemePage/CreateThemePage";
import CreateLanguage from "../components/createLanguage/CreateLanguage";
import EditTasks from "../components/editTasks/EditTasks";
import SettingPage from "../pages/settingsPage/SettingPage";
import StudentLayout from "../components/Layout/StudentLayout";
import TestStudentPage from "../pages/testSudentPage/TestStudentPage";
import StudentLayoutBack from "../components/Layout/StudentLayoutBack";
import StudentTestOne from "../components/studentTestOne/StudentTestOne";
import TaskInfoMentor from "../components/taskInfoMentor/TaskInfoMentor";

import TaskExecutionForSql from "../pages/taskExecutionForSql/taskExecutionForSql";

import TaskExecutionNew from "../components/taskExecutionNew/TaskExecutionNew";
import CreateQuestion from "../components/createQuesions/CreateQuestion";
import CreateSqlTable from "../components/createSqlTable/CreateSqlTable";


const Router = () => {

    return (
        <Routes>
            <Route path="/" element={<Login/>}/>
            <Route path="forgotPassword" element={<ForgotPass/>}/>


            <Route path="/" element={<StudentLayout/>}>
                <Route index path="taskList" element={<TaskList/>}/>
                <Route  path="testStudentPage" element={<TestStudentPage/>}/>
            </Route>

            <Route path="/" element={<StudentLayoutBack/>}>
                <Route index path="testStudentPage/:id" element={<StudentTestOne/>}/>
                <Route path ="/taskExecution/:id" element={<TaskExecutionNew/>}/>
            </Route>

            <Route path="/" element={<LayoutBack/>}>
                <Route path="taskExecutionForSql/" element={<TaskExecutionForSql/>}/> {/* поменять группу*/}
            </Route>

            <Route path="/" element={<Layout/>}>
                <Route index path="mentorPage" element={<MentorPage/>}/> {/* новая  страница менторов*/}
                <Route path="downloadPage" element={<DownloadPage/>}/>
                <Route path="testsPage" element={<TestsPage/>}/>
                <Route path="settingsPage" element={<SettingPage/>}/>
            </Route>

            <Route path="/" element={<LayoutBack/>}>
                <Route path="mentorPage/:id" element={<SettingsGroup/>}/> {/* поменять группу*/}

                <Route path="testsPage/:id" element={<CreateQuestion/>}/>
                <Route path="mentorPage/logPage/:id" element={<LogPage/>}/>
                <Route path="createTasks" element={<CreateTasks/>}/>
                <Route path="editTasks/:id" element={<EditTasks/>}/>
                <Route path="taskInfoMentor/:id" element={<TaskInfoMentor/>}/>
                <Route path="createTests" element={<CreateTest/>}/>
                <Route path="createThemePage" element={<CreateThemePage/>}/>
                <Route path="createLanguage" element={<CreateLanguage/>}/>
                <Route path="createSqlTable" element={<CreateSqlTable/>}/>
            </Route>

            <Route path="/change" element={<Change/>}/>
            <Route exact path="*" element={<NotPageFound/>}/>
        </Routes>
    );
};

export default Router;