import React, {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Preloader from "../preloader/Preloader";
import {fetchTaskPut, getOneStudentTask, getSQLRightResult} from "../../redux/studentsTaskSlice";
import OneExecution from "./oneExecution/OneExecution";
import {toast} from "react-toastify";


const TaskExecutionNew = () => {
    const tokenState = useSelector(state => state.authorization.token2);
    const preloader = useSelector(state => state.preloader.preloader);
    const infoStudentTask = useSelector(state => state.studentTasks.oneTaskInfo);

    const placePreloader = useSelector(state => state.studentTasks.preloader);

    const results = useSelector(state => state.studentTasks.results);
    const table = useSelector(state => state.studentTasks.table);


    const [studentAnswer, setStudentAnswer] = useState({
        solution: infoStudentTask.solution,
        sample_text: infoStudentTask.task.sample_text
    });
    const [nextUrl, setNextUrl] = useState({
        url: "",
        arr: [],
        key: ""

    })

    const dispatch = useDispatch()
    const {id} = useParams()
    const url = useLocation();

    useEffect(() => {
        if (tokenState.length) {
            dispatch(getOneStudentTask({tokenState, id}));

            if (url.state.key + 1 === url.state.arr.length) {
                setNextUrl({
                    url: "",
                    arr: [],
                    key: ""
                })
            } else {
                setNextUrl({
                    url: `/taskExecution/${url.state.arr[url.state.key + 1].id}`,
                    arr: url.state.arr,
                    key: url.state.key + 1
                })
            }
        }
    }, [tokenState, id]);

    useEffect(() => {
        setStudentAnswer({
            solution: infoStudentTask.solution,
            sample_text: infoStudentTask.task.sample_text
        });
    }, [infoStudentTask])


    const reset = () => {
        dispatch(getOneStudentTask({tokenState, id}));
    }

    const submitSolution = () => {
        const solution = studentAnswer.solution;
        if (!solution) {
            toast.error("Решение не должно быть пустым")
            return
        }
        dispatch(fetchTaskPut({tokenState, solution, id}))
    }

    const onChange = (e) => {
        setStudentAnswer({
            sample_text: e,
            solution: e
        })
    }

    const showRightResult = () => {
        dispatch(getSQLRightResult({id, tokenState}))
    }

    return (
        <div className="container-fluid my-container-student  pb-5">
            {preloader
                ?
                <Preloader/>
                :
                <OneExecution
                    nextUrl={nextUrl}
                    infoStudentTask={infoStudentTask}
                    studentAnswer={studentAnswer}
                    reset={reset}
                    submitSolution={submitSolution}
                    onChange={onChange}
                    showRightResult={showRightResult}
                    table={table}
                    results={results}
                    placePreloader={placePreloader}
                />
            }
        </div>
    )
}


export default TaskExecutionNew;