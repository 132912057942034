import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {API, BASE_URL} from "../api/api";
import {toast} from "react-toastify";
import {hidePreloader, showPreloader} from "./preloaderSlice";


export const getReportByStage = createAsyncThunk(
    'getReportByStage',
    async (data, {rejectWithValue, dispatch}) => {
        try {
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                }
            }

            const response = await fetch(`${BASE_URL}${API.student.getReportByStage}${data.id}/`, options);
            if (response.ok) {
                const data = await response.json()



                dispatch(getReportByStageReducer({data}))
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)


export const getReportBySearch = createAsyncThunk(
    'getReportBySearch',
    async (data, {rejectWithValue, dispatch}) => {
        try {
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                }
            }

            const response = await fetch(`${BASE_URL}${API.student.getReportByStage}${data.stageId}/?full_name=${data.name}`, options);
            if (response.ok) {
                const data = await response.json()
                dispatch(getReportByStageReducer({data}))
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const changePassCount = createAsyncThunk(
    'changePassCount',
    async (data, {rejectWithValue, dispatch}) => {
        const pass_count = {
            grade: data.pass_count
        }
        try {
            let options = {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                },
                body: JSON.stringify(pass_count)
            }
            const response = await fetch(`${BASE_URL}${API.student.changePassCount}${data.id}/`, options);

            if (response.ok) {
                const result = await response.json();
                const newData = {
                    id: data.stageId,
                    tokenState: data.tokenState,
                    name: data.searchValue,
                    stageId: data.stageId
                }
                // dispatch(getReportByStage(newData))
                dispatch(getReportBySearch(newData))


            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)


export const changePassCountFromLog = createAsyncThunk(
    'changePassCountFromLog',
    async (data, {rejectWithValue, dispatch}) => {
        const pass_count = {
            grade: data.pass_count
        }

        try {
            let options = {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `token ${data.tokenState[0].auth_token}`
                },
                body: JSON.stringify(pass_count)
            }
            const response = await fetch(`${BASE_URL}${API.student.changePassCount}${data.id}/`, options);

            if (response.ok) {
                const result = await response.json();
                toast.success("Оценка успешно изменена")
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)


const reportSlice = createSlice({
    name: 'reportSlice',
    initialState: {
        report: [
            {
                pass_grade: null,
                result: null,
                student__full_name: "",
                tasks: []
            }
        ]
    },
    reducers: {
        getReportByStageReducer(state, action) {
            state.report = action.payload.data
        }
    }
})
export const {getReportByStageReducer}= reportSlice.actions;

export default reportSlice.reducer