import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getOneResult, getStudentTests, hideModalReducer, showModalReducer} from "../../redux/testStudentSlice";
import {Link, useLocation} from "react-router-dom";
import classes from "./testStudents.module.css";
import eye from '../../images/eye.svg'
import ModalResultTest from "../../components/modalResultTest/ModalResultTest";
import Preloader from "../../components/preloader/Preloader";

const TestStudentPage = () => {
    const tokenState = useSelector(state => state.authorization.token2);
    const studentsTests = useSelector(state => state.studentTests.studentTests);
    const oneResultTest = useSelector(state => state.studentTests.oneResultTest);
    const modal = useSelector(state => state.studentTests.modal)
    const preloader = useSelector(state => state.preloader.preloader)

    let url = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (tokenState.length) {
            dispatch(getStudentTests(tokenState))
        }
    }, [tokenState]);

    const showModal = (e) => {
        const id = e.target.dataset.id
        dispatch(getOneResult({tokenState, id}))
        dispatch(showModalReducer())
    }

    const hideModal = () => {
        dispatch(hideModalReducer())
    }

    return (
        <>
            {modal && <ModalResultTest hideModal={hideModal} oneResultTest={oneResultTest} closeBtn={true}/>}
            <div className="container-fluid my-container-student">
                {
                    preloader
                        ?
                        <Preloader/>
                        :
                        <>
                            {
                                studentsTests.length > 0 ?
                                    studentsTests.map((test, key) => {
                                        let status = false;
                                        if (test.status === 2) {
                                            status = true;
                                        } else if (test.status === 1) {
                                            status = false;
                                        }

                                        return (
                                            <div key={key} className={classes.testWrapper}>
                                                <Link to={status ? `${url.pathname}/${test.test.id}` : ""}
                                                      state={{id: test.id, duration: test.test.duration, from: url.pathname}} key={key}
                                                      className={status ? classes.firstLink : classes.firstLinkNot}>
                                                    <span className="w-50">{test.test.name}</span>
                                                    <span
                                                        className={status ? classes.allowed : classes.notAllowed}><i></i><span>{status ? "Тест доступен" : "Тест закрыт"}</span></span>
                                                    <span
                                                        className={`w-25 ${classes.date}`}>{test.test.start_date} - {test.test.end_date}</span>
                                                </Link>

                                                <button className={classes.secondLink} onClick={showModal}
                                                        data-id={test.id}>
                                                    <img src={eye} alt={eye} data-id={test.id}/>
                                                    <span data-id={test.id}>Посмотреть результат</span>
                                                </button>

                                            </div>

                                        )
                                    })
                                    :
                                    <div className="fs-5">Тесты пока не были добавлены</div>
                            }
                        </>
                }
            </div>
        </>

    )
}


export default TestStudentPage;


