import {Outlet} from "react-router-dom";

import React from "react";
import GoBack from "../goBack/GoBack";


const Layout  = () => {

    return (
        <>
            <GoBack/>
            <Outlet/>
        </>

    )

}
export default Layout;