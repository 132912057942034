import React from 'react';
import classes from "../log/log.module.css";
import del from "../../images/deleteSvg.svg";
import edit from '../../images/edit.svg'


const LogVisits = ({
                       studentVisits,
                       onChangeDate,
                       createDate,
                       changePresense,
                       delDateModal,
                       updateDateModal,
                       dateFromModal
                   }) => {

    return (studentVisits.length > 0
            ?
            <>
                <div style={{marginBottom: "45px"}}>
                    <input type="date" className={classes.date} name="date" onChange={onChangeDate}
                           value={dateFromModal.date}/>
                    <button className={classes.createDate} onClick={createDate}>создать дату</button>
                </div>

                <div className={classes.logTable}>
                    <ul className={classes.logTableHeader}>
                        <li className={classes.search_wrap}>
                            <input type="search" placeholder="поиск" className={classes.btnSearch}/>
                        </li>
                        {
                            studentVisits[0].dates.map((date, key) =>
                                <li className={`${classes.headTable} d-flex align-items-center`} key={key}>
                                    {date.date}

                                        <button data-date={date.date} className={classes.delBtn}
                                                onClick={updateDateModal}>
                                            <img src={edit} alt={edit} data-date={date.date}/>
                                        </button>
                                        <button data-date={date.date} className={classes.delBtn} onClick={delDateModal}>
                                            <img src={del} alt={del} data-date={date.date}/>
                                        </button>

                                </li>)
                        }
                    </ul>
                    {
                        studentVisits.map((student, key) =>

                            <ul className={classes.logTableHeader} key={key}>
                                <li>{student.student__full_name}</li>
                                {student.dates.map((date, key2) => <li key={key2}
                                                                       className={date.presense ? classes.liOfNames : classes.liOfNamesNone}>

                                    <input
                                        type="text"
                                        value={date.presense ? "+" : "-"}
                                        onClick={e => e.target.select()}
                                        onChange={changePresense}
                                        max="1"
                                        min="0"
                                        style={{width: '70px'}}
                                        data-id={date.id}
                                    />

                                </li>)}


                            </ul>
                        )
                    }
                </div>
            </>


            : <div className={classes.logTable}>
                <ul className={classes.logTableHeader}>
                    <li className={classes.search_wrap}>
                        <input type="search" placeholder="поиск" className={classes.btnSearchForNo}/>
                    </li>
                </ul>


                <ul className={classes.logTableHeader}>
                    <li>
                        Студент не найден!
                    </li>
                </ul>
            </div>

    )
};

export default LogVisits;


