import React, {useEffect, useState} from "react";
import classes from "./createLanguage.module.css";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {createLanguage} from "../../redux/languageGroupSlice";




const CreateLanguage = () => {
    const tokenState = useSelector(state => state.authorization.token);
    const clearLanguage= useSelector(state=>state.languages.clearLanguage)
    const  dispatch = useDispatch();
    const [data, setData] = useState({
        title: clearLanguage.title
    });
    const onChange = (e) => {
        const target = e.target
        setData({
            ...data,
            [target.name]: target.value
        })
    }
    useEffect(()=>{
        setData({
            title:clearLanguage.title
        })
    },[clearLanguage])
    const onSubmit = () => {
        if(tokenState.length) {
            if(!data.title.trim()) {
                toast.error("Заполните поле")
                return
            }
        }

        dispatch(createLanguage({tokenState, data}))
    }


    return (
        <div className="container-fluid my-container-padding pt-5">
            <div className={classes.wrapper}>
                <h2>Создание языка</h2>
                <div className={`row ${classes.createThemeForm} d-flex flex-column`}>
                    <div className="col-lg-4 mb-5">
                        <label>
                            <span>Укажите язык</span>
                            <input type="text" name="title" placeholder="Введите язык" value={data.title} onChange={onChange}/>
                        </label>
                    </div>
                    <div className="w-100 text-end">
                        <button className={classes.btn} onClick={onSubmit}>Создать</button>
                    </div>

                </div>
            </div>
        </div>
    )
}


export default CreateLanguage;