import React from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/ext-language_tools";
import classes from "./aceEditorComponent.module.css";


const AceEditorComponent = ({value, onChange, language}) => {
    return (
        <AceEditor
            className={classes.aceEditorWrap}
            mode={language}
            theme="tomorrow"
            value={value}
            fontSize="16px"
            name="test_cases"
            width="100%"
            onChange={onChange}
            setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
            }}
        />
    )
}


export default AceEditorComponent
