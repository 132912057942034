import {configureStore} from "@reduxjs/toolkit";
import authorizationReducer from "./authorizationSlice";
import groupReducer from "./groupSlice";
import editReducer from "./editSlice";
import studentsTaskReducer from "./studentsTaskSlice";
import downloadTaskReducer from "./downloadTaskSlice";
import groupsInfoReducer from "./mentorGroupSlice";
import errorReducer from "./ErrorSlice";
import teachersReducer from './teachersSlice';
import languageReducer from './languageGroupSlice';
import mentorTasksReducer from './tasksSlice';
import stageReducer from './StageGroupSlice';
import getTagsReducer from './tagsSlice';
import getAllStageReducer from './getAllStudentStageSlice';
import getStageByIdReducer from './getTasksByStageSlice';
import themesReducer from './themeSlice';
import testsReducer from  './testSlice';
import testsStudentsReducer from './testStudentSlice'
import preloaderReducer from './preloaderSlice'
import getReportByStageReducer from './reportSlice'
import sqlResultReducer from "./sqlTableSlice";
import dataInputReducer from "./dataInputSlice";
import studentVisitsReducer from "./logVisitsSlice"
import delModalReducer from "./deleteModalSlice";
import paginationReducer from "./paginationSlice"
import questionsReducer from "./questionsSlice";



export default configureStore({
    reducer:{
        authorization: authorizationReducer,
        groups: groupReducer,
        edit: editReducer,
        studentTasks: studentsTaskReducer,
        downloadTask: downloadTaskReducer,
        groupsInfo: groupsInfoReducer,
        teachers: teachersReducer,
        languages: languageReducer,
        error: errorReducer,
        mentorTasks: mentorTasksReducer,
        stagesGroup: stageReducer,
        tags: getTagsReducer,
        allStage: getAllStageReducer,
        allTasksByStage: getStageByIdReducer,
        themes: themesReducer,
        tests: testsReducer,
        studentTests: testsStudentsReducer,
        preloader: preloaderReducer,
        reports: getReportByStageReducer,
        sql: sqlResultReducer,
        dataInput: dataInputReducer,
        studentsVisits: studentVisitsReducer,
        delModal: delModalReducer,
        pagination: paginationReducer,
        questionsReducer: questionsReducer
    }
})