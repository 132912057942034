import React from "react";


const Preloader = () => {
    return (
        <div className="preloader-wrap">
            <div className="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>

    )

}



export default Preloader;