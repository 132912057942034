import {createSlice} from "@reduxjs/toolkit";


const deleteModalSlice = createSlice({
    name: "deleteModalSlice",
    initialState: {
        status: false,
        calendarStatus: false,
    },
    reducers: {
        showDelModal(state) {
            state.status = !state.status
        },
        showCalendarModal(state) {
            state.calendarStatus = !state.calendarStatus
        }

    }
});


export const {showDelModal, showCalendarModal} = deleteModalSlice.actions;

export default deleteModalSlice.reducer;