import {createSlice} from "@reduxjs/toolkit";




const preloaderSlice = createSlice({
    name: "preloaderSlice",
    initialState: {
        preloader: false
    },
    reducers: {
        showPreloader(state) {
            state.preloader = true
        },
        hidePreloader(state) {
            state.preloader = false
        }
    }
})


export const {showPreloader, hidePreloader} = preloaderSlice.actions


export default preloaderSlice.reducer